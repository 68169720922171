import React, { useState, useEffect } from 'react';
import LaTeXToImage from './LatexToImage';
import WorksheetAnswersPDF from './WorksheetAnswersPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import 'katex/dist/katex.min.css';

const WorksheetAnswersButton = ({ worksheet, title }) => {
  const [latexExpressions, setLatexExpressions] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(true);
  const [generatedLatexIndexes, setGeneratedLatexIndexes] = useState([]);
  const [isPdfReady, setIsPdfReady] = useState(false); // New state to track PDF readiness

  useEffect(() => {
    const extractLatexExpressions = (worksheetArray) => {
      const expressions = [];
      worksheetArray.forEach((row) => {
        expressions.push(row[1][1].replaceAll('~', ''));
      });
      return expressions;
    };

    const latexArray = extractLatexExpressions(worksheet);
    setLatexExpressions(latexArray);

    setGeneratedImages(new Array(latexArray.length).fill(null));
  }, [worksheet]);

  useEffect(() => {
    if (latexExpressions.length > 0 && generatedLatexIndexes.length < latexExpressions.length) {
      latexExpressions.forEach((latex, index) => {
        if (!generatedLatexIndexes.includes(index)) {
          const latexButton = document.getElementById(`latex-container-${index}`);
          if (latexButton) latexButton.click();
        }
      });
    } else {
      setIsGenerating(false);
    }
  }, [latexExpressions, generatedLatexIndexes]);

  const handleImageGenerationComplete = (index, imageUrl) => {
    setGeneratedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = imageUrl;
      return updatedImages;
    });

    setGeneratedLatexIndexes((prevIndexes) => [...prevIndexes, index]);

    if (generatedLatexIndexes.length + 1 === latexExpressions.length) {
      setIsGenerating(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'none' }}>
        {latexExpressions.map((latex, index) => (
          <LaTeXToImage
            key={index}
            latex={latex}
            index={index}
            onImageGenerated={(imageUrl) => handleImageGenerationComplete(index, imageUrl)}
          />
        ))}
      </div>

      {isGenerating ? (
        <p className='text-center mt-[10px]'>Generating PDF... Please wait...</p>
      ) : (
        <PDFDownloadLink
          document={<WorksheetAnswersPDF images={generatedImages} worksheet={worksheet} title={title} />}
          fileName={title.replace(/ /g, "_") + ".pdf"}
          className="btn topicButton hover:cursor-pointer flex justify-center mb-[10px] mt-[10px]"
          style={{
            backgroundColor: isPdfReady ? '#DD7373' : '#9C9C9C',
            width: '200px',
            cursor: isPdfReady ? 'pointer' : 'wait'
          }}
        >
          {({ loading }) => {
            if (!loading && !isPdfReady) {
              setTimeout(()=> {
                setIsPdfReady(true); // Update when PDF is ready
              }, 3000);
            }
            return isPdfReady ? 'Download to PDF' : 'Loading document...';
          }}
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default WorksheetAnswersButton;
