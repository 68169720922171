import Fraction from 'fraction.js';
import { evaluate, sqrt, isPrime, simplify, format } from 'mathjs';
import { expand } from 'algebrite';

import { topicDecisionHigher } from './GCSEHigherQuestionGenerator';

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomFloat(min, max) {
    return Math.random() * (max - min + 1) + min;
}

const roundSignificantFigures = (value, significantFigures) => {
    const exponent = Math.floor(Math.log10(value));
    const nIntegers = exponent + 1;
    const precision = 10 ** (nIntegers - significantFigures);
    return Math.round(value / precision) * precision;
}

function generateFraction(allowMixed = false) {
    // Generate numerator and denominator
    let numerator = getRandomInt(1, 20);
    let denominator = getRandomInt(2, 20); // Ensure denominator is not 1 to avoid whole numbers

    if (allowMixed) {
        // Generate a whole number part for mixed fractions
        let wholeNumber = getRandomInt(1, 10);
        return `${wholeNumber} ${numerator}/${denominator}`; // Return as a mixed fraction
    } else {
        return `${numerator}/${denominator}`; // Return as a simple fraction
    }
}

function mixedToImproper(mixedFraction) {
    let wholeNumber = 0;
    let fractionPart = mixedFraction;

    // Check if there's a space (i.e., mixed fraction)
    if (mixedFraction.includes(' ')) {
        [wholeNumber, fractionPart] = mixedFraction.split(' ');
        wholeNumber = parseInt(wholeNumber); // Convert whole number to an integer
    }

    // Now extract the numerator and denominator from the fraction part
    let [numerator, denominator] = fractionPart.split('/').map(Number);

    // If it was a mixed fraction, calculate the improper numerator
    if (wholeNumber) {
        numerator = (wholeNumber * denominator) + numerator;
    }

    // Return the improper fraction as a string
    return `${numerator}/${denominator}`;
}

export function primeFactors(n) {
    const factors = [];
    let divisor = 2;

    while (n >= 2) {
        if (n % divisor === 0) {
        factors.push(divisor);
        n = n / divisor;
        } else {
        divisor++;
        }
    }
    return factors;
}

function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
            // Recursively check nested arrays
            if (!arraysEqual(arr1[i], arr2[i])) return false;
        } else if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

export default function blank() {
    console.log("blank function");
}

export function topicDecision(topic, complexity) {
    switch (complexity) {
        case 'foundation':
            switch(topic) {
                case 'hcf_and_lcm':
                    return HCF_LCM();
                case 'indices_laws':
                    return IndicesLaws();
                case 'estimation':
                    return Estimation();
                case 'fractions':
                    return Fractions();
                case 'percentage_increase':
                    return Percentage('increase');
                case 'percentage_decrease':
                    return Percentage('decrease');
                case 'expanding_expressions':
                    return ExpandingExpressions();
                case 'factorising_expressions':
                    return FactorisingExpressions();
                case 'solving_equations_one_step':
                    return SolvingEquations('one_step');
                case 'solving_equations_two_step':
                    return SolvingEquations('two_step');
                case 'solving_equations_both_sides':
                    return SolvingEquations('both_sides_basic');
                case 'solving_equations_brackets_one_side':
                    return SolvingEquations('brackets_one_side');
                case 'substitution':
                    return SubstitutionAlgebra('basic');
                case 'nth_term':
                    return LinearNthTerm();
                case 'expanding_quadratic_expressions':
                    return ExpandingQuadraticExpressions();
                case 'addition':
                    return Addition();
                case 'subtraction':
                    return Subtraction();
                case 'time':
                    return Time('basic');
                case 'simplify_fractions':
                    return SimplifyingFractions();
                case 'ordering_fractions':
                    return OrderingFractions();
                case 'place_value':
                    return PlaceValue();
                case 'rounding':
                    return FoundationRounding();
                case 'negative_numbers':
                    return NegativeNumbers();
                case 'powers_and_roots':
                    return PowersAndRoots();
                case 'bidmas':
                    return BIDMAS();
                case 'factors_and_multiples':
                    return Factors();
                case 'fraction_of_amount':
                    return FractionOfAmount();
                case 'fdp':
                    return FDP();
                case 'averages':
                    return Averages();
                case 'error_intervals':
                    return ErrorIntervals();
                case 'percentages':
                    return Percentages();
                case 'exchange_rates':
                    return ExchangeRates();
                case 'conversions_units':
                    return ConversionsUnits();
                case 'best_buy':
                    return BestBuy();
                case 'compound_interest':
                    return CompoundInterest();
                case 'reverse_percentages':
                    return ReversePercentages();
                case 'standard_form':
                    return StandardForm();
                case 'simplify_algebra':
                    return SimplifyAlgebra();
                case 'factorising_quadratic_expressions':
                    return FactoriseQuadratics();
                case 'solving_quadratics':
                    return SolvingQuadratics();
                case 'solving_simultaneous_equations':
                    return SimultaneousEquations();
                case 'inequalities':
                    return Inequalities();
                case 'simplifying_ratios':
                    return SimplifyRatios();
                case 'finding_quantities_using_ratios':
                    return FindingQuantitiesRatios();
                case 'ratio':
                    return Ratio();
                case 'proportion':
                    return Proportion();
                case 'direct_inverse_proportion_foundation':
                    return DirectInverseProportion();
                case 'speed':
                    return Speed();
                case 'probability':
                    return Probability();
                default:
                    return ['blank',['blank','[blank]'],'blank'];
            }
        
        case 'wordy':
            return ['blank',['blank','[blank]'],'blank'];

        case 'higher':
            let question = topicDecision(topic, 'foundation');
            if (arraysEqual(question, ['blank',['blank','[blank]'],'blank'])) {
                return topicDecisionHigher(topic);
            } else {
                return question;
            }
        default:
            return ['blank',['blank','[blank]'],'blank'];
    }
}

function HCF_LCM() {
    // choosing if HCM or LCM
    let question_type = getRandomInt(0,1);
    let question_type_string = '';
    if (question_type === 0) {
        question_type_string = 'Highest Common Factor (HCF)';
    }
    if (question_type === 1) {
        question_type_string = 'Lowest Common Multiple (LCM)';
    }
    
    let common = 1;
    let num1 = 1;
    let num2 = 1;
    let answer = 1;

    // Choosing a random number larger than 2 = common variable
    common = getRandomInt(2, 24);

    // random numbers
    num1 = common * getRandomInt(1, 12);
    num2 = common * getRandomInt(1, 12);
    while (num1 === num2) {
        num2 = common * getRandomInt(1, 12);
    }

    // getting prime numbers of num1 and num2
    let num1Prime = primeFactors(num1);
    let num2Prime = primeFactors(num2);

    let hcf;

    // looping from 1 to number1 and number2 to find HCF
    for (let i = 1; i <= num1 && i <= num2; i++) {

        // check if is factor of both integers
        if( num1 % i === 0 && num2 % i === 0) {
            hcf = i;
        }
    }

    // find LCM
    let lcm = 1;
    if (question_type === 1) {
        lcm = (num1 * num2) / hcf;
        answer = lcm;
    }
    if (question_type === 0) {
        answer = hcf;
    }

    let steps = `Find the prime factors of ${num1} and ${num2}. The prime factors of ${num1} are ${num1Prime.join(', ')}. The prime factors of ${num2} are ${num2Prime.join(', ')}. `;

    if (question_type === 0) {
        steps += `After that, group common prime numbers together and multiply the prime numbers that are in both factors of ${num1} and ${num2}.`
    }
    if (question_type === 1) {
        steps += `After that, group common prime numbers together and multiply all the prime numbers together.`
    }

    return [
        `Find the ${question_type_string} of ${num1} and ${num2}`,
        [answer,`~${answer}~`],
        steps
    ];
}

function IndicesLaws() {
    // Pick a letter
    const letters = ['x', 'y', 'z'];
    const letter = letters[0];

    // Picking the operation for the question
    const operations = ['*', '/', 'brackets'];
    const operation = operations[getRandomInt(0, operations.length-1)];

    // picking numbers
    const num1 = getRandomInt(-20,20);
    const num2 = getRandomInt(-20,20);

    let answerIndices = 0;
    let answer = null;

    if (operation === '*') {
        answerIndices = num1 + num2;
    }
    if (operation === '/') {
        answerIndices = num1 - num2;
    }
    if (operation === 'brackets') {
        answerIndices = num1 * num2;
    }

    answer = `${letter}^${answerIndices}`;
    const answerLatex = `~${letter}^{${answerIndices}}~`;

    if (answerIndices === 0) {
        answer = '1';
    }

    // writing out the question and steps depending on the operation
    let question;
    let steps;

    if (operation === '*') {
        question = `Find ~${letter}^{${num1}} \\times ${letter}^{${num2}}~`;
        steps = `Add together ${num1} and ${num2}.`;
    }
    if (operation === '/') {
        question = `Find ~${letter}^{${num1}} \\div ${letter}^{${num2}}~`;
        steps = `Subtract ${num2} away from ${num1}.`;
    }
    if (operation === 'brackets') {
        question = `Find ~(${letter}^{${num1}})^{${num2}}~`;
        steps = `Multiply together ${num1} and ${num2}.`;
    }

    return [question, [`${answer}`, answerLatex], steps];
}

function Estimation() {
    const operations = ['*', '/', '+', '-'];
    const operationsLatex = ['\\times', '\\div', '+', '-'];
    const operation1Num = getRandomInt(0,operations.length-1);
    const operation2Num = getRandomInt(0,operations.length-1);
    const operation1 = operations[operation1Num];
    const operation2 = operations[operation2Num];

    let num1, num2, num3, num1Rounded, num2Rounded, num3Rounded;
    // Math.round(num * p) / p
    // This function rounds the numbers to the number of decimal places specified in p
    // p should be a power of 10
    // In this case we are rounding to 2 decimal places
    num1 = Math.round(getRandomFloat(2,100) * 10) / 10;
    num2 = Math.round(getRandomFloat(2,100) * 10) / 10;
    num3 = Math.round(getRandomFloat(2,100) * 10) / 10;

    num1Rounded = parseFloat(roundSignificantFigures(num1, 1));
    num2Rounded = parseFloat(roundSignificantFigures(num2, 1));
    num3Rounded = parseFloat(roundSignificantFigures(num3, 1));

    let answer = 0;

    // performing the operations
    if (operation1 === '*') {
        answer = num1Rounded * num2Rounded;
    }
    if (operation1 === '/') {
        answer = num1Rounded / num2Rounded;
        while (num1Rounded % num2Rounded !== 0) {
            num1 = Math.round(getRandomFloat(2,10) * 10) / 10;
            num2 = Math.round(getRandomFloat(2,10) * 10) / 10;
            num1Rounded = parseFloat(roundSignificantFigures(num1, 1));
            num2Rounded = parseFloat(roundSignificantFigures(num2, 1));
            answer = num1Rounded / num2Rounded;
        }
    }
    if (operation1 === '+') {
        answer = num1Rounded + num2Rounded;
    }
    if (operation1 === '-') {
        answer = num1Rounded - num2Rounded;
    }

    if (operation2 === '*') {
        answer = answer * num3Rounded;
    }
    if (operation2 === '/') {
        //answer = answer / num3Rounded;
        while (num3Rounded > answer) {
            num3 = Math.round(getRandomFloat(2,answer) * 10) / 10;
            num3Rounded = parseFloat(roundSignificantFigures(num3, 1));
        }
        while ((answer % num3Rounded) !== 0) {
            num3 = Math.round(getRandomFloat(2,answer) * 10) / 10;
            num3Rounded = parseFloat(roundSignificantFigures(num3, 1));
        }
        answer = answer / num3Rounded;
    }
    if (operation2 === '+') {
        answer = answer + num3Rounded;
    }
    if (operation2 === '-') {
        answer = answer - num3Rounded;
    }

    // Rounding answer to 2 D.P.
    answer = Math.round(answer * 100) / 100;

    let question = `Estimate ~(${num1} ${operationsLatex[operation1Num]} ${num2}) ${operationsLatex[operation2Num]} ${num3} ~`;
    let steps = `
        To calculate the answer to this question, start by rounding the numbers to 1 significant figure. 
        Round ${num1} to ${num1Rounded}, round ${num2} to ${num2Rounded}, and ${num3} to ${num3Rounded}. 
        Then perform the calculation within the brackets and then use that answer to perform the operation with the number and operation outside the brackets.
    `;

    return [question, [answer,`~${answer}~`], steps];
}

function Fractions() {
    // choose an operation
    const operations = ['*', '/', '+', '-'];
    const operationsLatex = ['\\times', '\\div', '+', '-'];
    const operationNum = getRandomInt(0,operations.length-1);
    const operation = operations[operationNum];

    // generating the fractions
    //let fract1, fract1nom, fract1denom, fract2, fract2nom, fract2denom;

    const fract1 = generateFraction(getRandomInt(0,1));
    const fract2 = generateFraction(getRandomInt(0,1));

    // converting the fractions to improper fractions in case they are mixed fractions
    let fract1Temp = mixedToImproper(fract1);
    let fract2Temp = mixedToImproper(fract2);

    let answer = evaluate(`(${fract1Temp}) ${operation} (${fract2Temp})`);
    answer = new Fraction(answer);

    const fract1Latex = new Fraction(fract1);
    const fract2Latex = new Fraction(fract2);

    let question = `Calculate ~${fract1Latex.toLatex()} ${operationsLatex[operationNum]} ${fract2Latex.toLatex()}~. Put your answer in its simpliest form as an improper fraction.`;
    let steps;
    if (operation[operationNum] === '*') {
        steps = `
            To multiply fractions, first multiply the numerators (top numbers) together, and then multiply the denominators (bottom numbers) together.
        `
    }
    if (operation[operationNum] === '/') {
        steps = `
        To divide fractions, multiply the first fraction by the reciprocal (flip) of the second fraction.
        `;
    }
    if (operation[operationNum] === '+') {
        steps = `
            To add fractions, make sure the denominators (bottom numbers) are the same, then add the numerators (top numbers) and keep the denominator unchanged.
        `;
    }
    if (operation[operationNum] === '-') {
        steps = `
            To subtract fractions, make sure the denominators (bottom numbers) are the same, then subtract the numerators (top numbers) and keep the denominator unchanged.
        `;
    }

    return [question, [mixedToImproper(answer.toFraction(true)), `~${answer.toLatex()}~`], steps];
}

function Percentage(mode) {
    const percentage = getRandomInt(1,100);
    const amount = getRandomInt(2,500);

    let question, answer, answerLatex, steps;

    if (mode === 'increase') {
        answer = amount * ((100 + percentage) / 100);

        question = `Increase £${amount} by ${percentage}%`;

        steps = `
            To increase £${amount} by ${percentage}%, first calculate ${percentage}% of £${amount} by converting the percentage to a decimal: ${percentage}%=${percentage}/100=${percentage/100}.
            Then, multiply £${amount} by ${percentage/100} to find the amount to add: ${amount}×${percentage/100}=${Math.round((amount*(percentage/100)) * 100) / 100}.
            Finally, add this amount to the original value: ${amount}+${Math.round((amount*(percentage/100)) * 100) / 100}=${Math.round(answer * 100) / 100}.
            This means the answer is £${Math.round(answer * 100) / 100}.
        `;
    }
    if (mode === 'decrease') {
        answer = amount * ((100 - percentage) / 100);
        question = `Decrease £${amount} by ${percentage}%`;
        steps = `
            To decrease £${amount} by ${percentage}%, first calculate ${percentage}% of £${amount} by converting the percentage to a decimal: ${percentage}%=${percentage}/100=${percentage/100}.
            Then, multiply £${amount} by ${percentage/100} to find the amount to subtract: ${amount}×${percentage/100}=${Math.round((amount*(percentage/100)) * 100) / 100}.
            Finally, subtract this amount from the original value: ${amount}-${Math.round((amount*(percentage/100)) * 100) / 100}=${Math.round(answer * 100) / 100}.
            This means the answer is £${Math.round(answer * 100) / 100}.
        `;
    }

    answer = Math.round(answer * 100) / 100;
    if (!Number.isInteger(answer)) answer = answer.toFixed(2);
    answerLatex = `~£${answer}~`;

    return [question, [`£${answer}`, answerLatex], steps];
}

function ExpandingExpressions() {
    // choose an operation
    const operations = ['+', '-'];
    const operationsLatex = ['+', '-'];
    const operationNum = getRandomInt(0,operations.length-1);
    const operation = operations[operationNum];

    const letters = ['x','y','p','q','r','a','b','c'];
    const letter = letters[getRandomInt(0,letters.length-1)];

    // choosing the number on the outside of the brackets
    const outsideNum = getRandomInt(2,16);
    const coefficient = getRandomInt(2,100);
    const otherNumBrackets = getRandomInt(100,100);

    let answer = `${outsideNum*coefficient}${letter}`;
    if (outsideNum*otherNumBrackets > 0) {
        answer += `+${outsideNum*otherNumBrackets}`;
    }
    if (outsideNum*otherNumBrackets < 0) {
        answer += `${outsideNum*otherNumBrackets}`;
    }

    let question = `${outsideNum}(${coefficient}${letter}`;
    if (otherNumBrackets > 0) {
        question += `+${otherNumBrackets}`;
    }
    if (otherNumBrackets < 0) {
        question += `${otherNumBrackets}`;
    }
    if (otherNumBrackets === 0) {
        question = `${outsideNum}(${coefficient}${letter}`;
    }

    const steps = `
        Start by multiplying the ${outsideNum} by everything inside the brackets (${outsideNum} multiplied by ${coefficient}${letter}, ${outsideNum} multiplied by ${otherNumBrackets}).
        After that put your answers together to get ${answer}.
    `;

    return [`Expand ~${question})~`,[`${answer}`,`~${answer}~`], steps];
}

function FactorisingExpressions() {
    let outsideNum = getRandomInt(2,20);
    
    let coefficient = getRandomInt(2,20);
    let insideNum = getRandomInt(2,20);

    let coefficientMultiplied = outsideNum*coefficient;
    let insideNumMultiplied = outsideNum*insideNum;

    
    // looping until both numbers are equal
    let number1 = coefficientMultiplied;
    let number2 = insideNumMultiplied;

    while(number1 !== number2){
        if(number1 > number2) {
            number1 -= number2;
        }
        else {
            number2 -= number1;
        }
    }
    
    outsideNum = number1;

    const question = `${coefficient*outsideNum}x+${insideNum*outsideNum}`;
    const answer = `${outsideNum}(${coefficient}x+${insideNum})`;
    const steps = `
        To factorise "${question}", first look for the greatest common factor (GCF) of both terms. 
        In this case, the GCF of ${coefficient*outsideNum} and ${insideNum*outsideNum} is ${outsideNum}. 
        Next, divide both terms by the GCF: ${coefficient*outsideNum}x ÷ ${outsideNum} = ${coefficient}x and ${insideNum*outsideNum} ÷ ${outsideNum} = ${insideNum}. 
        So the answer would be: ${answer}. 
    `

    return [`Factorise ~${question}~`,[answer,`~${answer}~`], steps];
}

function SolvingEquations(mode) {
    let question, answer, steps;

    if (mode === 'one_step') {
        const coefficient = getRandomInt(2,25);
        answer = getRandomInt(2,50);
        const rightSide = coefficient*answer;
        question = `${coefficient}x = ${rightSide}`;

        steps = `
            To solve the equation ${question}, you want to find out what x is. Start by getting x by itself. 
            To do this, divide both sides of the equation by ${coefficient}. This means you take the ${rightSide} and divide it by ${coefficient} as well.
            So, you get x=${answer}.
        `;

        return [`Solve ~${question}~`, [`x=${answer}`,`~x=${answer}~`], steps];
    }

    if (mode === 'two_step') {
        const coefficient = getRandomInt(2,15);
        answer = getRandomInt(2,50);
        const rightSide = coefficient*answer;
        let leftSideNumber = getRandomInt(-25,25);
        while (leftSideNumber === 0) {
            leftSideNumber = getRandomInt(-25,25);
        }

        if (leftSideNumber > 0) {
            question = `${coefficient}x + ${leftSideNumber} = ${rightSide+leftSideNumber}`;
            steps = `
            To solve the equation ${question}, the goal is to find out what x is. 
            Start by getting rid of the ${leftSideNumber} on the left side. 
            To do that, subtract ${leftSideNumber} from both sides of the equation, which gives ${coefficient}x = ${rightSide} + ${leftSideNumber}, simplifying to ${coefficient}x = ${rightSide+leftSideNumber}. 
            Now, to isolate x, divide both sides by ${coefficient}: x÷${coefficient}=${rightSide+leftSideNumber}÷${coefficient}. 
            Simplifying this gives x=${answer}. So, the solution is x=${answer}.
            `;
        }
        if (leftSideNumber < 0) {
            question = `${coefficient}x ${leftSideNumber} = ${rightSide+leftSideNumber}`;
            steps = `
            To solve the equation ${question}, the goal is to find out what x is. 
            Start by getting rid of the ${leftSideNumber} on the left side. 
            To do that, add ${leftSideNumber} from both sides of the equation, which gives ${coefficient}x = ${rightSide} + ${leftSideNumber}, simplifying to ${coefficient}x = ${rightSide+leftSideNumber}. 
            Now, to isolate x, divide both sides by ${coefficient}: x÷${coefficient}=${rightSide+leftSideNumber}÷${coefficient}. 
            Simplifying this gives x=${answer}. So, the solution is x=${answer}.
            `;
        }
        
        return [`Solve ~${question}~`, [`x=${answer}`,`~x=${answer}~`], steps];
    }

    if (mode === 'both_sides_basic') {
        const coefficient = getRandomInt(2,15);
        answer = getRandomInt(2,50);
        const rightSide = coefficient*answer;
        let leftSideNumber = getRandomInt(-25,25);
        while (leftSideNumber === 0) {
            leftSideNumber = getRandomInt(-25,25);
        }
        let rightSideCoefficient = getRandomInt(2,25);
        while (rightSideCoefficient === 0) {
            rightSideCoefficient = getRandomInt(2,25);
        }

        if (leftSideNumber < 0 && rightSide+leftSideNumber < 0) {
            question = `${coefficient+rightSideCoefficient}x ${leftSideNumber} = ${rightSideCoefficient}x ${rightSide+leftSideNumber}`;
        }
        if (leftSideNumber > 0 && rightSide+leftSideNumber < 0) {
            question = `${coefficient+rightSideCoefficient}x + ${leftSideNumber} = ${rightSideCoefficient}x ${rightSide+leftSideNumber}`;
        }
        if (leftSideNumber > 0 && rightSide+leftSideNumber > 0) {
            question = `${coefficient+rightSideCoefficient}x + ${leftSideNumber} = ${rightSideCoefficient}x + ${rightSide+leftSideNumber}`;
        }
        if (leftSideNumber < 0 && rightSide+leftSideNumber > 0) {
            question = `${coefficient+rightSideCoefficient}x ${leftSideNumber} = ${rightSideCoefficient}x + ${rightSide+leftSideNumber}`;
        }

        steps = `
            To solve the equation ${question.replace(/\s+/g, "")}, the goal is to find x. Start by getting all the x terms on one side. 
            ${rightSideCoefficient > 0 ? ('Subtract ' + rightSideCoefficient) : ('Add '+rightSideCoefficient)} from both sides:
            ${(`${coefficient+rightSideCoefficient}x ${rightSideCoefficient > 0 ? ('-' + rightSideCoefficient+'x') : ('+'+rightSideCoefficient+'x')} ${leftSideNumber}=${rightSide+leftSideNumber}`).replace(/\s+/g, "")};

            This simplifies to ${(`${coefficient}x ${leftSideNumber}= ${rightSide+leftSideNumber}`).replace(/\s+/g, "")}. Now, get rid of the ${leftSideNumber < 0 ? (-leftSideNumber+' by adding '+(-leftSideNumber)) : (leftSideNumber+' by subtracting '+(leftSideNumber))} to both sides:
            ${coefficient}x=${rightSide}.
            Finally, divide both sides by ${coefficient} to isolate x. So, the solution to x=${answer}.
        `;

        steps = steps.trim().split(/[\s,\t,\n]+/).join(' ');

        return [`Solve ~${question}~`,[`x=${answer}`,`~x=${answer}~`],steps];
    }

    if (mode === 'brackets_one_side') {
        let coefficient = getRandomInt(2,15);
        answer = getRandomInt(2,20);
        let rightSide = coefficient*answer;
        let leftSideNumber = getRandomInt(-12,12);
        while (leftSideNumber === 0) {
            leftSideNumber = getRandomInt(-12,12);
        }

        const multiplier = getRandomInt(2,12);
        /*coefficient = coefficient * multiplier;
        rightSide = rightSide * multiplier;
        leftSideNumber = leftSideNumber;*/

        if (leftSideNumber > 0) {
            question = `${multiplier}(${(coefficient)}x + ${(leftSideNumber)}) = ${(rightSide+leftSideNumber)*multiplier}`;
            steps = `
            To solve the equation ${question}, the goal is to find out what x is. 
            Start by multipling out the brackets and getting rid of the ${leftSideNumber} on the left side. 
            To do that, subtract ${leftSideNumber} from both sides of the equation, which gives ${coefficient}x = ${rightSide} + ${leftSideNumber}, simplifying to ${coefficient}x = ${rightSide+leftSideNumber}. 
            Now, to isolate x, divide both sides by ${coefficient}: x÷${coefficient}=${rightSide+leftSideNumber}÷${coefficient}. 
            Simplifying this gives x=${answer}. So, the solution is x=${answer}.
            `;
        }
        if (leftSideNumber < 0) {
            question = `${multiplier}(${(coefficient)}x ${(leftSideNumber)}) = ${(rightSide+leftSideNumber)*multiplier}`;
            steps = `
            To solve the equation ${question}, the goal is to find out what x is. 
            Start by getting rid of the ${leftSideNumber} on the left side. 
            To do that, add ${leftSideNumber} from both sides of the equation, which gives ${coefficient}x = ${rightSide} + ${leftSideNumber}, simplifying to ${coefficient}x = ${rightSide+leftSideNumber}. 
            Now, to isolate x, divide both sides by ${coefficient}: x÷${coefficient}=${rightSide+leftSideNumber}÷${coefficient}. 
            Simplifying this gives x=${answer}. So, the solution is x=${answer}.
            `;
        }
        steps = steps.trim().split(/[\s,\t,\n]+/).join(' ');

        return [`Solve ~${question}~`,[`x=${answer}`,`~x=${answer}~`],steps];
    }
}

function SubstitutionAlgebra(mode) {
    let question, answer, steps;

    if (mode === 'basic') {
        const subNum = getRandomInt(1,50);
        let coefficient = getRandomInt(2,25);
        let num1 = getRandomInt(-25,25);

        while ((coefficient * subNum) < num1) {
            num1 = getRandomInt(-25,25);
        }

        question = `There is an equation: ~y = ${coefficient}x ${(num1 > 0) ? (`+${num1}`) : (`${num1}`)}~.  Find the value of y when x=${subNum}`;
        answer = (coefficient * subNum) + num1;
        steps = `
            To find the value of y when x=${subNum} in the equation y=${coefficient}x${((num1 > 0) ? (`+${num1}`) : (`${num1}`)).replace(/\s+/g, "")}, start by substituting ${subNum} for x. This gives:
            y=${coefficient}(${subNum})${((num1 > 0) ? (`+${num1}`) : (`${num1}`)).replace(/\s+/g, "")}
            Now, multiply ${coefficient} by ${subNum}:
            y=${coefficient*subNum}${((num1 > 0) ? (`+${num1}`) : (`${num1}`)).replace(/\s+/g, "")}.
            Finally, ${(num1 > 0) ? (`add ${num1}`) : (`subtract ${-num1}`)} from ${coefficient*subNum}:
            y=${answer}.
            So, when x=${subNum}, the value of y is ${answer}.
        `;
        if (num1 === 0) {
            question = `There is an equation: ~y = ${coefficient}x~. Find the value of y when x=${subNum}`;
            steps = `Multiply ${coefficient} by ${subNum}.`;
        }

        steps = steps.trim().split(/[\s,\t,\n]+/).join(' ');

        return [question,[answer,`~${answer}~`],steps];
    }
}

function LinearNthTerm() {
    let question, answer, steps;

    let difference = getRandomInt(-25,25);
    let commonAdditive = getRandomInt(-25,25);

    while (difference === 0) {
        difference = getRandomInt(-25,25);
    }

    const nthterm = (term) => {
        return (term*difference)+commonAdditive;
    }

    question = `Here are the first 5 terms of an arithmetic sequence. Find an expression, in terms of n, for the nth term of this sequence.~~${nthterm(1)},${nthterm(2)},${nthterm(3)},${nthterm(4)},${nthterm(5)}~~`;
    answer = `${difference}n${(commonAdditive > 0) ? '+'+commonAdditive : commonAdditive}`;
    if (commonAdditive === 0) {
        answer = `${difference}n`;   
    }

    steps = `
    Find the common difference for the sequence.
    Multiply the values for n = 1, 2, 3.
    Add or subtract a number to obtain the sequence given in the question.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answer}~`], steps];
}

function ExpandingQuadraticExpressions() {
    let question, answer, steps;

    let num1 = getRandomInt(-16,16);
    let num2 = getRandomInt(-16,16);
    while (num1 === 0) {
        num1 = getRandomInt(-16,16);
    }
    while (num2 === 0) {
        num2 = getRandomInt(-16,16);
    }
    let x1 = getRandomInt(1,10);
    let x2 = getRandomInt(1,10);

    question = `Expand and simplify ~(${x1 === 1 ? '': x1}x${(num1 > 0) ? ('+'+num1) : (num1)})(${x2 === 1 ? '' : x2}x${(num2 > 0) ? ('+'+num2) : (num2)})~`;

    let firstNumber = x1*x2;
    let middleNumber = (num1*x2)+(num2*x1);
    let endNumber = num1*num2;
    answer = `${firstNumber === 1 ? '' : firstNumber}x^2${(middleNumber > 0) ? ('+'+middleNumber) : (middleNumber)}x${(endNumber > 0) ? ('+'+endNumber) : (endNumber)}`;
    if (middleNumber === 0) {
        answer = `${firstNumber === 1 ? '' : firstNumber}x^2${(endNumber > 0) ? ('+'+endNumber) : (endNumber)}`;
    }

    steps = `
        To expand the quadratic (${x1 === 1 ? '': x1}x${(num1 > 0) ? ('+'+num1) : (num1)})(${x2 === 1 ? '' : x2}x${(num2 > 0) ? ('+'+num2) : (num2)}), 
        you multiply each part of the first bracket by each part of the second bracket. 
        First, multiply ${x1 === 1 ? '': x1}x by ${x2 === 1 ? '': x2}x to get ${firstNumber}x^2. 
        Then, multiply ${x1 === 1 ? '': x1}x by ${num2} to get ${x1*num2}x, and next multiply ${num1} by ${x2 === 1 ? '': x2}x to get ${x2*num1}x. 
        Finally, multiply ${num1} by ${num2}. 
        After that, add ${num1*x2}x and ${num2*x1}x together to get the final expression: ${answer}.
    `;
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answer}~`], steps];
}

function Addition() {
    let question, answer, steps;

    const num1 = parseFloat(getRandomFloat(1,200).toFixed(2));
    const num2 = parseFloat(getRandomFloat(1,200).toFixed(2));

    const sum = Math.round((parseFloat(num1)
    + parseFloat(num2)) * 1000) / 1000;

    question = `Work out: ${num1} + ${num2}`;
    answer = sum;

    steps = `
        To solve ${num1}+${num2}, first line up the numbers by their decimal points.
        Then start adding the numbers together. The total should be ${answer}.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answer}~`], steps];
}

function Subtraction() {
    let question, answer, steps;

    let num1 = parseFloat(getRandomFloat(1,200).toFixed(2));
    let num2 = parseFloat(getRandomFloat(1,200).toFixed(2));

    while (num2 > num1) {
        num2 = parseFloat(getRandomFloat(1,200).toFixed(2));
    }

    const sum = Math.round((parseFloat(num1)
    - parseFloat(num2)) * 1000) / 1000;

    question = `Work out: ${num1} - ${num2}`;
    answer = sum;

    steps = `
        To solve ${num1}+${num2}, first line up the numbers by their decimal points.
        Then start adding the numbers together. The total should be ${answer}.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answer}~`], steps];
}

function Time(mode) {
    let question, answer, steps;

    if (mode === 'basic') {
        const timeChoice = getRandomInt(0,2);

        let timeNum;

        // seconds to minutes
        if (timeChoice === 0) {
            timeNum = getRandomInt(60,7200);
            question = `Change ${timeNum} seconds into minutes.`;
            answer = Math.round((timeNum/60) * 100) / 100;
            steps = `Divide ${timeNum} by 60`;
            while (!Number.isInteger(answer)) {
                timeNum = getRandomInt(60,7200);
                question = `Change ${timeNum} seconds into minutes.`;
                answer = Math.round((timeNum/60) * 100) / 100;
                steps = `Divide ${timeNum} by 60`;
            }
        }
        // minutes to hours
        if (timeChoice === 1) {
            timeNum = getRandomInt(60,3600);
            question = `Change ${timeNum} minutes into hours.`;
            answer = Math.round((timeNum/60) * 100) / 100;
            steps = `Divide ${timeNum} by 60`;
            while (!Number.isInteger(answer)) {
                timeNum = getRandomInt(60,7200);
                question = `Change ${timeNum} minutes into hours.`;
                answer = Math.round((timeNum/60) * 100) / 100;
                steps = `Divide ${timeNum} by 60`;
            }
        }

        // hours to minutes
        if (timeChoice === 2) {
            answer = getRandomInt(1,360);
            timeNum = Math.round((answer / 60) * 100) / 100;
            question = `Change ${timeNum} hours into minutes.`;
            steps = `Multiply ${timeNum} by 60`;
            while (!Number.isInteger(timeNum)) {
                timeNum = getRandomInt(1,360);
                question = `Change ${timeNum} hours into minutes.`;
                answer = Math.round((timeNum/60) * 100) / 100;
                steps = `Multiply ${timeNum} by 60`;
            }
        }
    }

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answer}~`], steps];
}

function SimplifyingFractions() {
    let question, answer, steps;

    let num1 = getRandomInt(2,32);
    let num1Factors = [];
    for (let i=1; i <= num1; i++) {
        if ((num1 % i) === 0) {
            num1Factors.push(i);
        }
    }
    let num2 = getRandomInt(2,32);
    let num2Factors = [];
    for (let i=2; i <= num2; i++) {
        if ((num2 % i) === 0) {
            num2Factors.push(i);
        }
    }
    const multiplier = getRandomInt(2,10);
    num1 = multiplier*num1;
    num2 = multiplier*num2;

    question = `Simplify fully: ~\\frac{${num1}}{${num2}}~`;
    answer = new Fraction(num1, num2);
    let n = answer.n; // numerator
    let d = answer.d; // denominator
    let answerLatex = answer.toLatex();
    answer = answer.toFraction();

    let hcf = num1;
    let number2 = num2;

    while(hcf !== number2){
        if(hcf > number2) {
           hcf -= number2;
        }
        else {
            number2 -= hcf;
        }
    }

    steps = `
        To simplify the fraction ${num1}/${num2}, first find the highest common factor (HCF) of ${num1} and ${num2}, which is the largest number that divides both evenly. 
        The HCF between ${num1} and ${num2} is ${hcf}. Now, divide both the numerator (${num1}) and the denominator (${num2}) by ${hcf}. 
        This gives you ${num1} ÷ ${hcf} = ${n} and ${num2} ÷ ${(num1)/(n)} = ${d}. So, the simplified fraction is ${answer}.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answerLatex}~`], steps];
}

function OrderingFractions() {
    let question, answer, steps;

    const generateFractionNormal = () => {
        let d = getRandomInt(1,12); // denominator
        let n = getRandomInt(1,d-1); // numerator
        return [n,d];
    }

    let fractionsList = [];
    let fractionsListLatex = [];
    let multiplier;

    for (let i=0; i < getRandomInt(3,5); i++) {
        let currentFraction;
        if (i === 0) {
            currentFraction = generateFractionNormal();
        } else {
            multiplier = getRandomInt(1,5);
            let d = fractionsList[0].fraction[1]*multiplier;
            let n = getRandomInt(1,d-1);
            currentFraction = [n,d];
        }
        
        // checking if current fraction is in list already
        while (fractionsList.includes(currentFraction)) {
            multiplier = getRandomInt(1,6);
            if (multiplier !== 1) {
                while (multiplier % 2 !== 0) {
                    multiplier = getRandomInt(1,6);
                }
            }
            let d = fractionsList[0][1]*multiplier;
            let n = getRandomInt(1,d-1);
            currentFraction = [n,d];
        }

        fractionsListLatex.push(`\\frac{${currentFraction[0]}}{${currentFraction[1]}}`)
        fractionsList.push({'id': i, 'fraction': currentFraction});
    }

    let fractionsListDecimal = [];
    for (let i=0; i < fractionsList.length; i++) {
        let current = fractionsList[i];
        let currentFraction = (new Fraction(current.fraction[0], current.fraction[1])).valueOf();
        fractionsListDecimal.push({'id': current.id, 'fraction': currentFraction});
    }

    fractionsListDecimal.sort((a, b)=> a.fraction - b.fraction);

    let answers = fractionsListDecimal.map(decimal => {
        let thisFraction = decimal.fraction;
        // getting which fraction is the equivalent in the original list
        for (let i=0; i < fractionsList.length; i++) {
            if (fractionsList[i].id === decimal.id) {
                thisFraction = fractionsList[i].fraction;
            }
        }
        return {'id': decimal.id, 'fraction': thisFraction/*new Fraction(thisFraction[0], thisFraction[1]).toLatex()*/}; // Convert decimal back to fraction
    });

    answer = answers.map((currentAnswer)=> {
        return `${currentAnswer.fraction[0]}/${currentAnswer.fraction[1]}`;
    });
    answer = answer.join(', ');
    let answerLatex = answers.map((currentAnswer)=> {
        return `\\frac{${currentAnswer.fraction[0]}}{${currentAnswer.fraction[1]}}`;
    });

    question = `Write the following fractions in order of size. Start with the smallest fraction. ~~${fractionsListLatex.join('\\quad')}~~`;
    steps=`To order fractions, you can start by finding a common denominator for all the fractions and then comparing the fractions from there.
    Alternatively, you can convert the numbers to decimals and then compare from there and put the numbers in order.
    `;
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answerLatex}~`], steps];
}

function PlaceValue() {
    let question, answer, steps;

    function hasDuplicateDigits(number) {
        let numString = number.toString(); // Convert number to string
        let digitCount = {}; // Object to keep track of digit counts
    
        for (let i = 0; i < numString.length; i++) {
            let digit = numString[i];
            // Increment the count for this digit or initialize it to 1 if it doesn't exist
            digitCount[digit] = (digitCount[digit] || 0) + 1;
        }
    
        // Check if any digit appears more than once
        for (let digit in digitCount) {
            if (digitCount[digit] > 1) {
                return true; // A duplicate was found
            }
        }
    
        return false; // No duplicates
    }

    let num = getRandomInt(10,10000);
    while (hasDuplicateDigits(num)) {
        num = getRandomInt(10,10000);
    }
    const index = getRandomInt(0,(num.toString()).length-1);

    question = `Write down the value of the ${num.toString()[index]} in the number ${num}`;
    let numAnswer = num.toString()[index];
    answer = `${numAnswer+'0'.repeat((num.toString()).length-1-index)}`;
    steps = `   
    Firstly, locate the digit within the number.
    After that, recall the place value of that column.
    Finally, write the value of the digit.
    `;
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question, [answer, `~${answer}~`], steps];
}

function FoundationRounding() {
    let question, answer, steps;

    const roundingChoices = ['1 decimal place', '2 decimal places', 'nearest hundred', 'nearest thousand'];
    const roundingChoice = roundingChoices[getRandomInt(0,roundingChoices.length-1)];

    let num = 0;

    if (roundingChoice === '1 decimal place') {
        num = Math.round(getRandomFloat(1,500) * 100) / 100;
        question = `Write ${num} correct to ${roundingChoice}.`;
        answer = Math.round(num * 10) / 10;
        steps = `
            To round a number to one decimal place, first identify the second decimal place, which is the digit immediately after the one you want to round. 
            If the second decimal place is 5 or greater, increase the first decimal place by 1. 
            If it's less than 5, leave the first decimal place as it is. 
            Finally, remove any extra digits beyond the first decimal place.
        `;
    }
    
    if (roundingChoice === '2 decimal places') {
        num = Math.round(getRandomFloat(1,500) * 1000) / 1000;
        question = `Write ${num} correct to ${roundingChoice}.`;
        answer = Math.round(num * 100) / 100;
        steps = `
            To round a number to two decimal places, first identify the third decimal place, which is the digit immediately after the one you want to round. 
            If the third decimal place is 5 or greater, increase the second decimal place by 1. 
            If it's less than 5, leave the second decimal place as it is. 
            Finally, remove any extra digits beyond the second decimal place.
        `;
    }
    if (roundingChoice === 'nearest hundred') {
        num = getRandomInt(100,10000);
        question = `Write ${num} to the ${roundingChoice}.`;
        answer = Math.round(num / 100) * 100;
        steps = `
            To round a number to the nearest hundred, first identify the digit in the tens place. 
            If the tens digit is 5 or greater, increase the hundreds digit by 1. 
            If the tens digit is less than 5, leave the hundreds digit unchanged. 
            Finally, replace the digits in the tens and ones places with zeros.
        `;
    }
    if (roundingChoice === 'nearest thousand') {
        num = getRandomInt(1000,10000);
        question = `Write ${num} to the ${roundingChoice}.`;
        answer = Math.round(num / 1000) * 1000;
        steps = `
            To round a number to the nearest thousand, first identify the digit in the hundreds place. 
            If the hundreds digit is 5 or greater, increase the thousands digit by 1. 
            If the hundreds digit is less than 5, leave the thousands digit unchanged. 
            Finally, replace the digits in the hundreds, tens, and ones places with zeros.
        `;
    }

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function NegativeNumbers() {
    let question, answer, steps;

    const operations = ['+', '-', '*', '/'];
    const operationsLatex = ['+','-','\\times','\\div'];
    const operationNum = getRandomInt(0,operations.length-1);
    const operation = operations[operationNum];

    let num1 = getRandomInt(-20,20);
    let num2 = getRandomInt(-20,20);
    if (operations[operationNum] === '/') {
        while (num1 % num2 !== 0) {
            num1 = getRandomInt(-20,20);
            num2 = getRandomInt(-20,20);
        }
    }

    question = `Work out ~${num1}${operationsLatex[operationNum]}${num2}~`;
    if (operations[operationNum] === '+') {
        answer = num1+num2;
        steps = `
            Add together ${num1} and ${num2}.
        `;
    }
    if (operations[operationNum] === '-') {
        answer = num1-num2;
        steps = `
            Subtract ${num2} from ${num1}.
        `;
    }
    if (operations[operationNum] === '*') {
        answer = num1*num2;
        steps = `
            Multiply ${num1} and ${num2}.
        `;
    }
    if (operations[operationNum] === '/') {
        answer = Math.round((num1/num2)*100)/100;
        steps = `
            Divide ${num1} by ${num2}.
        `;
    }

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function PowersAndRoots() {
    let question, answer, steps;

    const choices = ['powers','roots'];
    const choice = choices[getRandomInt(0,choices.length-1)];

    if (choice === 'powers') {
        const base = getRandomInt(1,10);
        const power = getRandomInt(2,5);
        question = `Work out ~${base}^{${power}}~`;
        answer = base ** power;
        steps = `Multiply ${base} by itself ${power} times.`;
    }
    if (choice === 'roots') {
        answer = getRandomInt(1,10);
        const root = getRandomInt(2,4);
        const questionNum = answer ** root;
        if (root !== 2) {
            question = `Work out ~\\sqrt[${root}]{${questionNum}}~`;
        } else {
            question = `Work out ~\\sqrt{${questionNum}}~`;
        }
        steps = `
        To calculate the ${root}th root of ${questionNum}, start by guessing a number and check if raising it to the power of ${root} gives the desired result. 
        Repeat the process with different guesses or use a calculator for precision if needed.
        `;
    }

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function BIDMAS() {
    let question, answer, steps;

    const operations = ['+','-','*','/'];
    const operationsLatex = ['+','-','\\times','\\div'];
    const operationNum1 = getRandomInt(0,operations.length-1);
    const operationNum2 = getRandomInt(0,operations.length-1);
    const operation1 = operations[operationNum1];
    const operation2 = operations[operationNum2];

    let num1, num2, num3;
    num3 = getRandomInt(1,10);
    num2 = num3*getRandomInt(1,10);
    num1 = num2*getRandomInt(1,10);

    if (operation1 === '/') {
        num2 = getRandomInt(1,10);
        num1 = num2*getRandomInt(1,10);
    }
    if (operation2 === '/') {
        num3 = getRandomInt(1,10);
        num2 = num3*getRandomInt(1,10);
    }
    if (operation1 === '/' && operation2 === '/') {
        num3 = getRandomInt(1,10);
        num2 = num3*getRandomInt(1,10);
        num1 = num2*getRandomInt(1,10);
    }

    answer = evaluate(`${num1} ${operations[operationNum1]} ${num2} ${operations[operationNum2]} ${num3}`);

    question = `Work out ~${num1} ${operationsLatex[operationNum1]} ${num2} ${operationsLatex[operationNum2]} ${num3}~`;

    steps = `
    To solve any expression using BIDMAS (Brackets, Indices, Division and Multiplication, Addition and Subtraction), follow the correct order of operations. 
    Start by solving anything inside brackets first. Then handle any exponents or powers (indices). 
    Next, perform any division or multiplication from left to right, as they are equal in priority. 
    Finally, complete any addition or subtraction from left to right.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function Factors() {
    let question, answer, steps;

    const num = getRandomInt(2,144);
    let factorsNum = [];
    for (let i=1; i <= num; i++) {
        if (num % i === 0) {
            factorsNum.push(i);
        }
    }
    answer = factorsNum.join(', ');
    question = `Write down all the factors of ${num}.`;
    steps = `
        To find the factors of a number, look for all the whole numbers that divide evenly into it without leaving a remainder. 
        Start by checking if 1 is a factor (which it always is) and then test the number itself, as it is also always a factor. 
        After that, try dividing the number by other integers to see if they result in whole numbers.
    `;
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function FractionOfAmount() {
    let question, answer, steps;

    const multiple = getRandomInt(2,12);
    const fractionAmount = getRandomInt(1,multiple-1);
    const amount = multiple*getRandomInt(2,12);
    
    question = `Find ~\\frac{${fractionAmount}}{${multiple}}~ of ${amount}`;
    answer = Math.round(((amount * fractionAmount) / multiple) * 100) / 100;

    steps = `Multiply ${amount} by ${fractionAmount} and then divide it by ${multiple} to get ${answer}.`;
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function FDP() {
    let question, answer, answerLatex, steps;

    const types = ['fraction', 'decimal', 'percentage'];
    const type = types[getRandomInt(0,types.length-1)];

    if (type === 'fraction') {
        let amount = getRandomInt(1,100);
        while (amount === 100) {
            amount = getRandomInt(1,100);
        }
        const fraction = new Fraction(amount, 100);
        const typesTo = ['decimal', 'percentage'];
        const typeTo = typesTo[getRandomInt(0,typesTo.length-1)];
        question = `Write ~${fraction.toLatex()}~ as a ${typeTo}.`;
        if (typeTo === 'decimal') {
            answer = fraction.toString();
            answerLatex = fraction.toString();
            steps = `
                Ensure the fraction is written with just a numerator and a denominator.
                (if the number is a mixed number you first need to convert it to an improper fraction).
                Divide the numerator by the denominator.
            `;
        }
        if (typeTo === 'percentage') {
            answer = (Math.round(fraction*100)/1).toString()+'%';
            answerLatex = (Math.round(fraction*100)/1).toString()+'\\%';
            steps = `
                Divide the numerator by the denominator and then multiply the result by 100.
            `;
        }
    }

    if (type === 'decimal') {
        const amount = Math.round(((getRandomInt(1,100))/100) * 100) / 100;
        const typesTo = ['fraction', 'percentage'];
        const typeTo = typesTo[getRandomInt(0,typesTo.length-1)];
        question = `Write ${amount} as a ${typeTo}.`;
        if (typeTo === 'fraction') {
            const fraction = new Fraction(amount);
            answer = `${fraction.n}/${fraction.d}`;
            answerLatex = fraction.toLatex();
            steps = `
                Write the decimal as a fraction by dividing by 1 (this will make the denominator 1)
                Convert the numerator to an integer by multiplying by a multiple of 10, e.g. 10, 100, 1000. You need to do the same to the denominator to create an equivalent fraction
                Simplify the fraction where possible
            `;
        }
        if (typeTo === 'percentage') {
            answer = (Math.round(amount*100)/1)+'%';
            answerLatex = (Math.round(amount*100)/1)+'\\%';
            steps = `
                Multiply the decimal by 100 to move the decimal point twice to the right.
            `;
        }
    }

    if (type === 'percentage') {
        const amount = Math.round(((getRandomInt(1,100))/100) * 100);
        const typesTo = ['fraction', 'decimal'];
        const typeTo = typesTo[getRandomInt(0,typesTo.length-1)];
        question = `Write ${amount}% as a ${typeTo}.`;
        if (typeTo === 'fraction') {
            const fraction = new Fraction(amount/100);
            answer = `${fraction.n}/${fraction.d}`;
            answerLatex = fraction.toLatex();
            steps = `
                Put ${amount} over 100 (without the % sign) and simplify the fraction if it can be.
            `;
        }
        if (typeTo === 'decimal') {
            answer = (Math.round(amount/100)/1);
            answerLatex = (Math.round(amount*100)/1);
            steps = `
                Divide the percentage by 100 so that the decimal point moves twice to the left.
            `;
        }
    }

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function SimplifyAlgebra() {
    let question, answer, answerLatex, steps;

    const operations = ['+','-','*'];
    const operationsLatex = ['+','-','\\times'];
    const operationNum1 = getRandomInt(0,operations.length-1);
    const operationNum2 = getRandomInt(0,operations.length-1);
    const operation1 = operations[operationNum1];
    const operation2 = operations[operationNum2];

    const letters = ['x','y'];
    const letter1 = letters[getRandomInt(0,letters.length-1)];
    const letter2 = letters[getRandomInt(0,letters.length-2)];
    const letter3 = letters[getRandomInt(0,letters.length-1)];

    let num1, num2, num3;
    num3 = getRandomInt(1,10);
    num2 = getRandomInt(1,10);
    num1 = getRandomInt(1,10);

    if (operation1 === '/') {
        num2 = getRandomInt(1,10);
        num1 = num2*getRandomInt(1,10);
    }
    if (operation2 === '/') {
        num3 = getRandomInt(1,10);
        num2 = num3*getRandomInt(1,10);
    }
    if (operation1 === '/' && operation2 === '/') {
        num3 = getRandomInt(1,10);
        num2 = num3*getRandomInt(1,10);
        num1 = num2*getRandomInt(1,10);
    }

    question = `Work out ~${num1}${letter1} ${operationsLatex[operationNum1]} ${num2}${letter2} ${operationsLatex[operationNum2]} ${num3}${letter3}~`;
    answer = simplify(`${num1}${letter1} ${operation1} ${num2}${letter2} ${operation2} ${num3}${letter3}`);

    answer = expand(`${answer}`);

    if (answer.toString().indexOf('*') !== -1) {
        answer = answer.toString().replaceAll('*','');
    }

    steps = `
        Use BIDMAS to figure out which operation to perform first, once you have done that perform the operation between the two numbers.
        Once you have got the answer from that, perform the operation with the other number to get the final answer.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function Averages() {
    function mostCommon(arr) {
        const frequencyMap = {};
    
        // Step 1: Create a frequency map
        arr.forEach(item => {
            if (frequencyMap[item]) {
                frequencyMap[item] += 1;
            } else {
                frequencyMap[item] = 1;
            }
        });
    
        // Step 2: Find the item with the highest frequency
        let mostCommonItem = null;
        let maxCount = 0;
    
        for (const item in frequencyMap) {
            if (frequencyMap[item] > maxCount) {
                mostCommonItem = item;
                maxCount = frequencyMap[item];
            }
        }
    
        return [mostCommonItem, maxCount];
    }

    let question, answer, steps;

    // Make a list of numbers
    let numsList = Array.from({length: getRandomInt(3,10)}, ()=> getRandomInt(2,30));

    // Have an array of the different averages (range, mode, mean, median)
    const averages = ['range', 'mode', 'mean', 'median'];

    // Pick one of the averages
    const currentAverage = averages[getRandomInt(0,averages.length-1)];

    // get the sorted array
    let sortedNumsList = numsList;
    sortedNumsList = sortedNumsList.sort((a, b)=> a - b);

    // Calculate that average
    if (currentAverage === 'range') {
        let lowest = sortedNumsList[0];
        let highest = sortedNumsList[sortedNumsList.length-1];
        answer = `${highest-lowest}`;
        steps = `
            Find the highest and lowest numbers in the list of numbers and subtract the biggest from the smallest.
            In this case it would be ${highest} subtract ${lowest} to get ${answer} as your answer.
        `;
    }
    if (currentAverage === 'mode') {
        answer = mostCommon(numsList);
        if (answer[1] === 1) {
            let num = numsList[getRandomInt(0,numsList.length-1)]
            numsList.push(num);
            answer = num;
        } else {
            answer = answer[0];
        }
        steps = `
            Find the most common number in the list of numbers.
        `;
    }
    if (currentAverage === 'mean') {
        answer = numsList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        answer = Math.round((answer/numsList.length)*100)/100;
        steps = `
            Add up all the numbers together and divide by how many numbers there are.
        `;
    }
    if (currentAverage === 'median') {
        if (sortedNumsList.length % 2 === 0) {
            const mid1 = sortedNumsList.length / 2 - 1;  
            const mid2 = sortedNumsList.length / 2;      
            answer = ((sortedNumsList[mid1] + sortedNumsList[mid2]) / 2).toFixed(2);
            steps = `
            Sort the numbers highest to lowest and then find the middle number.
            Since there are two numbers in the middle here, find the mean between those two numbers by adding them together and dividing it by two.
            `;
        }
        if (sortedNumsList.length % 2 !== 0) {
            answer = sortedNumsList[((sortedNumsList.length-1)/2)];
            steps = `
            Sort the numbers highest to lowest and then find the middle number.
            `;
        }
    }

    question = `Here is a list of ${numsList.length} numbers. Find the ${currentAverage}. ~~${numsList.join(',\\quad')}~~`;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function ErrorIntervals() {
    function errorIntervalSignificantFigures(roundedValue, significantFigures) {
        // Calculate the step size based on significant figures
        const stepSize = Math.pow(10, Math.floor(Math.log10(roundedValue)) - (significantFigures - 1));
    
        // Calculate the lower and upper bounds
        const lowerBound = roundedValue - stepSize / 2;
        const upperBound = roundedValue + stepSize / 2;
    
        return {
            lowerBound: lowerBound,
            upperBound: upperBound
        };
    }

    let question, answer, answerLatex, steps;

    const types = ['1 decimal place', '2 decimal places', '2 significant figures', '3 significant figures'];
    const type = types[getRandomInt(0,types.length-1)];

    let num = 1;

    if (type === '1 decimal place') {
        num = Math.round(getRandomFloat(1,30) * 10) / 10;
        const lower = Math.round((num-0.05)*100)/100;
        const higher = Math.round((num+0.05)*100)/100;

        answer = `${lower} ≤ y < ${higher}`;
        answerLatex = `${lower} \\leq y < ${higher}`;
        steps = `
            To find the error interval for ${num} to ${type}, you will need to find the size of the error range.
            In this case you will need to find the first decimal to find the error range.
            Divide this by 2, then add and subtract it from the given value to get the maximum and minimum values. 
            Finally, write the error range as an inequality.
        `;
        
    }
    if (type === '2 decimal places') {
        num = Math.round(getRandomFloat(1,30) * 100) / 100;
        const lower = Math.round((num-0.005)*1000)/1000;
        const higher = Math.round((num+0.005)*1000)/1000;

        answer = `${lower} ≤ y < ${higher}`;
        answerLatex = `${lower} \\leq y < ${higher}`;
        steps = `
            To find the error interval for ${num} to ${type}, you will need to find the size of the error range.
            In this case you will need to find the second decimal to find the error range.
            Divide this by 2, then add and subtract it from the given value to get the maximum and minimum values. 
            Finally, write the error range as an inequality.
        `;
    }
    if (type === '2 significant figures') {
        num = Math.round(getRandomFloat(1,30) * 100) / 100;
        num = Math.round(roundSignificantFigures(num,2) * 100) / 100;
        const result = errorIntervalSignificantFigures(num, 2);
        const lower = Math.round(result.lowerBound * 100) / 100;
        const higher = Math.round(result.upperBound * 100) / 100;
        answer = `${lower} ≤ y < ${higher}`;
        answerLatex = `${lower} \\leq y < ${higher}`;
        steps = `
            To find the error interval for ${num} to ${type}, you will need to find the size of the error range.
            In this case you will need to find the second digit to find the error range.
            Divide this by 2, then add and subtract it from the given value to get the maximum and minimum values. 
            Finally, write the error range as an inequality.
        `;
    }
    if (type === '3 significant figures') {
        num = Math.round(getRandomFloat(1,30) * 100) / 100;
        num = Math.round(roundSignificantFigures(num,3) * 100) / 100;
        const result = errorIntervalSignificantFigures(num, 3);
        const lower = Math.round(result.lowerBound * 100) / 100;
        const higher = Math.round(result.upperBound * 100) / 100;
        answer = `${lower} ≤ y < ${higher}`;
        answerLatex = `${lower} \\leq y < ${higher}`;
        steps = `
            To find the error interval for ${num} to ${type}, you will need to find the size of the error range.
            In this case you will need to find the third digit to find the error range.
            Divide this by 2, then add and subtract it from the given value to get the maximum and minimum values. 
            Finally, write the error range as an inequality.
        `;
    }
    

    question = `A number y is rounded to ${type}. The result is ${num}. Write down the error interval for y.`;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function Percentages() {
    let question, answer, steps;

    // Example question: Work out 10% of £95
    let num = getRandomInt(1,500);
    let currentPercentage = getRandomInt(1,99);
    if (!Number.isInteger(num * (currentPercentage / 100))) {
        num = num*10;
    }

    question = `Work out ${currentPercentage}% of ${num}`;
    answer = num * (currentPercentage / 100);
    answer = Math.round(answer * 100) / 100;
    answer = `${answer}`;
    
    steps = `
        To calculate ${currentPercentage}% of ${num}, you can start by dividing ${currentPercentage} by 100 to get ${currentPercentage/100}.
        After that you can multiply that ${currentPercentage/100} by ${num} to get a final answer of ${answer}.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function ExchangeRates() {
    let question, answer, steps;

    // [Currency Name, Exchange Rate for Currency, Country]
    const currencies = [
        ["Euros", 1.15, "France"], 
        ["Australian dollars", 2.1, "Australia"], 
        ["Turkish lira", 7.7, "Turkey"], 
        ["Japanese Yen", 140, "Japan"], 
        ["Indian rupees", 92, "India"], 
        ["American Dollars", 1.31, "USA"]
    ];

    const names = [
        ["William",
        "George",
        "Thomas",
        "Henry",
        "Harry",
        "James",
        "Matthew",
        "Fred",
        "Alfred",
        "Charlie",
        "Charles",
        "Alfie",
        "Freddie",
        "Aaron",
        "Bradley",
        "Dean",
        "David",
        "Kyle",
        "Luke",
        "Terry",
        "Tom",
        "Oliver",
        "Ollie",
        ],
        [
        "Alice",
        "Florence",
        "Elsie",
        "Ivy",
        "Violet",
        "Lily",
        "Gladys",
        "Doris",
        "Hilda",
        "Ethel",
        "Abbie",
        "Eve",
        "Claire",
        "Freya",
        "Lucy",
        "Amy",
        "Emma",
        "Emily",
        "Lydia",
        "Martha",
        "Eloise",
        "Lottie",
        "Olivia",
        "Kez",
        "Maddie",
        "Megan",
        "Phoebe",
        "Susan",
        "Suzanne",
        "Sophie",
        "Tanya",
        "Victoria",
        "Zoe"
    ]];
    const gender = getRandomInt(0,1);
    const name = names[gender][getRandomInt(0,names.length-1)];

    // pick if converting from other currency to pounds OR pounds to other currency
    let otherCurrency = currencies[getRandomInt(0,currencies.length-1)];

    const conversion = getRandomInt(0,1);
    // other currency to pounds
    if (conversion === 0) {
        answer = getRandomInt(2,500);
        let amountOther = Math.round((answer*otherCurrency[1])*100)/100;

        question = `
            ${name} is on holiday in ${otherCurrency[2]}.
            ${gender === 0 ? 'He' : 'She'} changes ${amountOther} ${otherCurrency[0]} to pounds.
            The exchange rate is £1 = ${otherCurrency[1]} ${otherCurrency[0]}.
            Calculate how many pounds ${name} receives. Round to the nearest pound.
        `;

        steps = `
            Divide ${amountOther} by ${otherCurrency[1]} to convert ${otherCurrency[0]} to pounds.
        `;
    }
    // pounds to other currency
    if (conversion === 1) {
        let amountPounds = getRandomInt(2,500);
        answer = Math.round((amountPounds*otherCurrency[1])*100)/100;

        question = `
            ${name} is on holiday in ${otherCurrency[2]}.
            ${gender === 0 ? 'He' : 'She'} changes ${amountPounds} pounds to ${otherCurrency[0]}.
            The exchange rate is £1 = ${otherCurrency[1]} ${otherCurrency[0]}.
            Calculate how many ${otherCurrency[0]} ${name} receives. Round to the nearest ${otherCurrency[0]}.
        `;

        steps = `
            Multiply ${amountPounds} by ${otherCurrency[1]} to convert ${otherCurrency[0]} to pounds.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function ConversionsUnits() {
    let question, answer, steps;

    const units = [['grams','kilograms'],['kilograms','grams'],['kilometres','metres'],['metres','kilometres'],['cm','mm'],['mm','cm'],['millilitres','litres'],['litres','millilitres']];
    const conversion = getRandomInt(0,units.length-1); // picking which unit for conversions

    let amount = 0;

    if (conversion === 0) { // grams to kilograms
        amount = getRandomInt(100, 10000); // grams
        answer = Math.round((amount/1000) * 100) / 100;
        steps = `
            Divide ${amount} by 1000.
        `;
    }
    if (conversion === 1) { // kilograms to grams
        amount = Math.round((getRandomFloat(1, 100))*100)/100; // kilograms
        answer = amount*1000;
        steps = `
            Multiply ${amount} by 1000.
        `;
    }
    if (conversion === 2) { // kilometres to metres
        amount = Math.round((getRandomFloat(1, 100))*100)/100;
        answer = amount*1000;
        steps = `
            Multiply ${amount} by 1000.
        `;
    }
    if (conversion === 3) { // metres to kilometres
        amount = getRandomInt(100, 10000); 
        answer = Math.round((amount/1000) * 100) / 100;
        steps = `
            Divide ${amount} by 1000.
        `;
    }
    if (conversion === 4) { // cm to mm
        amount = getRandomInt(10, 100);
        answer = amount*10; 
        steps = `
            Multiply ${amount} by 10.
        `;
    }
    if (conversion === 5) { // mm to cm
        amount = getRandomInt(100, 1000);
        answer = Math.round((amount/10) * 100) / 100;
        steps = `
            Divide ${amount} by 10.
        `;
    }
    if (conversion === 6) { // millilitres to litres
        amount = getRandomInt(100, 10000);
        answer = Math.round((amount/1000) * 100) / 100;
        steps = `
            Divide ${amount} by 1000.
        `;
    }
    if (conversion === 7) { // litres to millilitres
        amount = Math.round((getRandomFloat(1, 100))*100)/100;
        answer = amount*1000;
        steps = `
            Multiply ${amount} by 1000.
        `;
    }

    question = `
        Change ${amount} ${units[conversion][0]} to ${units[conversion][1]}.
    `;


    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function BestBuy() {
    let question, answer, answerLatex, steps;

    // Example question:
    // Snacks can be bought in
    // a pack of 8 biscuits for £1.92
    // a pack of 10 biscuits for £2.55
    // Which of these two packs is better value for money?
    
    const objects = ['biscuits', 'chocolates', 'crisps', 'cupcakes'];
    const myObject = objects[getRandomInt(0,objects.length-1)];

    let pack1Size = getRandomInt(1,12);
    let pack2Size = getRandomInt(1,12);
    while (pack2Size === pack1Size) {
        pack2Size = getRandomInt(1,12);
    }

    let priceSinglePack1 = getRandomFloat(0.01,3);
    priceSinglePack1 = priceSinglePack1.toFixed(2);
    let pack1Price = pack1Size*priceSinglePack1;
    pack1Price = pack1Price.toFixed(2);
    let priceSinglePack2 = getRandomFloat(0.01,3);
    while (priceSinglePack1 === priceSinglePack2) {
        priceSinglePack2 = getRandomFloat(0.01,3);
    }
    priceSinglePack2 = priceSinglePack2.toFixed(2);
    let pack2Price = pack2Size*priceSinglePack2;
    pack2Price = pack2Price.toFixed(2);

    if (pack1Price < pack2Price) {
        answer = 'Pack 1';
        answerLatex = 'Pack\\ 1';
    }
    if (pack1Price > pack2Price) {
        answer = 'Pack 2';
        answerLatex = 'Pack\\ 2';
    }

    question = `${myObject.charAt(0).toUpperCase() + myObject.slice(1)} can be bought in two types of packs. 
    Pack 1 is a pack of ${pack1Size} that can be bought for £${pack1Price}. 
    Pack 2 is a pack of ${pack2Size} that can be bought for £${pack2Price}.
    Is Pack 1 or Pack 2 cheaper?
    `;
    
    steps = `
        To find which of Pack 1 and Pack 2 are cheaper, you have to find out how much of one pack costs.
        To do that divide the number in a pack by the cost of the pack. 
        For Pack 1, divide ${pack1Price} by ${pack1Size} to get ${priceSinglePack1}.
        For Pack 2, divide ${pack2Price} by ${pack2Size} to get ${priceSinglePack2}.
        From those two values you can now see ${answer} is cheaper.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function CompoundInterest() {
    let question, answer, answerLatex, steps;

    const names = [
        ["William",
        "George",
        "Thomas",
        "Henry",
        "Harry",
        "James",
        "Matthew",
        "Fred",
        "Alfred",
        "Charlie",
        "Charles",
        "Alfie",
        "Freddie",
        "Aaron",
        "Bradley",
        "Dean",
        "David",
        "Kyle",
        "Luke",
        "Terry",
        "Tom",
        "Oliver",
        "Ollie",
        ],
        [
        "Alice",
        "Florence",
        "Elsie",
        "Ivy",
        "Violet",
        "Lily",
        "Gladys",
        "Doris",
        "Hilda",
        "Ethel",
        "Abbie",
        "Eve",
        "Claire",
        "Freya",
        "Lucy",
        "Amy",
        "Emma",
        "Emily",
        "Lydia",
        "Martha",
        "Eloise",
        "Lottie",
        "Olivia",
        "Kez",
        "Maddie",
        "Megan",
        "Phoebe",
        "Susan",
        "Suzanne",
        "Sophie",
        "Tanya",
        "Victoria",
        "Zoe"
    ]];
    const gender = getRandomInt(0,1);
    const name = names[gender][getRandomInt(0,names.length-1)];

    // Example Question
    // Annie invests £9500 for 5 years in a savings account.
    // She gets 1.8% per annum compound interest.
    // How much money does Annie have at the end of 5 years?

    const amount = getRandomInt(10,1000);
    const percentage = getRandomInt(1,25);
    const time = getRandomInt(2,10);

    const type = getRandomInt(0,1);

    // compound interest
    if (type === 0) {
        question = `${name} invests £${amount} for ${time} years in a savings account.
        ${gender ? 'She':'He'} gets ${percentage}% per annum compound interest.
        How much money does Annie have at the end of ${time} years?`;
        answer = amount * ((1+(percentage/100))**time);
        answer = Math.round(answer * 100) / 100;
        steps = `
            Add the interest rate ${percentage}% to 100%.
            Convert this percentage into a decimal to find the multiplier.
            Multiply £${amount} by the multiplier raised to the power of the ${time}.
        `;
    }
    // depreciation
    if (type === 1) {
        question = `${name} bought a new car for £${amount}.
        Each year the car depreciates in value by ${percentage}%.
        Work out how much the car will be valued after ${time} years.`;
        answer = amount * ((1-(percentage/100))**time);
        answer = Math.round(answer * 100) / 100;
        steps = `
            Subtract the depreciation rate from 100%
            Convert this percentage into a decimal to find the multiplier.
            Multiply the initial value by the multiplier raised to the power of the number of years (or given time period).
        `;
    }

    if (!Number.isInteger(answer)) {
        answer = parseFloat(answer).toFixed(2);
    }
    answer = `£${answer}`;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function ReversePercentages() {
    let question, answer, steps;

    const type = getRandomInt(0,1);

    let amount, percentage;

    if (type === 0) { // car
        // Example Question:
        // In a sale, the normal price of a car is reduced by 30%
        // The sale price of the car is £6300
        // Work out the normal price of the car.

        amount = getRandomInt(1000,10000);
        percentage = getRandomInt(1,50);

        answer = (Math.round((amount / ((100-percentage)/100))*100)/100).toFixed(2);
        answer = `£${answer}`;
        question = `
        In a sale, the normal price of a car is reduced by ${percentage}%. 
        The sale price of the car is £${amount}.
        Work out the normal price of the car.
        `;
        steps = `
            Start by converting ${percentage}% to a decimal (${percentage/100}).
            After that take away ${percentage/100} from 1 to find out how much the amount would have decreased by.
            This would get you ${((100-percentage)/100)}.
            Now divide £${amount} by ${((100-percentage)/100)} to get your answer.
        `;
    }
    if (type === 1) { // car
        // Example Question:

        amount = getRandomInt(10,150);
        percentage = getRandomInt(5,20);

        answer = (Math.round((amount / ((100+percentage)/100))*100)/100).toFixed(2);
        answer = `£${answer}`;
        question = `
        The price of a train ticket increases by ${percentage}%.
        The price of the train ticket now is £${amount}.
        Work out the price of the train ticket before the increase.
        `;
        steps = `
            Start by converting ${percentage}% to a decimal (${percentage/100}).
            After that add ${percentage/100} to 1 to find out how much the amount would have increased by.
            This would get you ${((100+percentage)/100)}.
            Now divide £${amount} by ${((100+percentage)/100)} to get your answer.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function StandardForm() {
    let question, answer, answerLatex, steps;

    const type = getRandomInt(0,1);

    if (type === 0) {
        answer = getRandomInt(100,10000);
        answerLatex = answer;
        let amount = format(answer, { notation: 'exponential' });
        question = `Write ~${amount.split('e')[0]}\\times10^{${amount.split('e')[1].replace('+','')}}~ as an ordinary number.`;
        steps = `
            To write a number in standard form, start by placing the decimal point after the first non-zero digit. 
            Then, rewrite the number with just the digits up to the first few decimal places. 
            Next, count how many places you moved the decimal point to get from the original position to the new position. 
            This count becomes the power of 10. If you moved the decimal point to the left, the power of 10 will be positive.
        `;
    }
    if (type === 1) {
        let questionNum = getRandomInt(100,10000);
        let amount = format(questionNum, { notation: 'exponential' });
        answer = `${amount.split('e')[0]}*10^${amount.split('e')[1].replace('+','')}`;
        answerLatex = `${amount.split('e')[0]}\\times10^{${amount.split('e')[1].replace('+','')}}`;
        question = `Write ${questionNum} in standard form.`;
        steps = `
            To change a number from standard form into an ordinary number, look at the power that is next to the 10.
            This power tells you how many places to move the decimal point.
            If the power is positive, move the decimal point to the right; if the power is negative, move it to the left. 
            Move the decimal the same number of places as the power shows. 
            If you need extra digits, add zeros. 
            This will give you the ordinary number.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function FactoriseQuadratics() {
    let question, answer, answerLatex, steps;

    // creating the expression
    let num1Inside = getRandomInt(-10,10);
    let num2Inside = getRandomInt(-10,10);

    const a = 1;
    const b = num1Inside + num2Inside;
    let c = num1Inside * num2Inside;
    while (c === 0) {
        num1Inside = getRandomInt(-10,10);
        num2Inside = getRandomInt(-10,10);
        c = num1Inside * num2Inside;
    }

    question = `Factorise ~x^{2} ${b > 0 ? '+'+b+'x' : b+'x'} ${c > 0 ? '+'+c : c}~`;
    if (c === 0) {
        question = `Factorise ~x^{2} ${b > 0 ? '+'+b+'x' : b+'x'}~`;
    }
    if (b === 0) {
        question = `Factorise ~x^{2} ${c > 0 ? '+'+c : c}~`;
    }

    answer = `(x${num1Inside > 0 ? '+'+num1Inside : num1Inside})(x${num2Inside > 0 ? '+'+num2Inside : num2Inside})`;
    answerLatex = answer;

    steps = `
        To factorise this expression, start by finding factors of ${c} that add up to ${b}.
        Once you have found a pair that works together you can put the number in two sets brackets next to the x's.
        The answer you should get should be ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function SolvingQuadratics() {
    let question, answer, answerLatex, steps;

    // creating the expression
    let num1Inside = getRandomInt(-10,10);
    let num2Inside = getRandomInt(-10,10);

    const a = 1;
    const b = num1Inside + num2Inside;
    let c = num1Inside * num2Inside;
    while (c === 0) {
        num1Inside = getRandomInt(-10,10);
        num2Inside = getRandomInt(-10,10);
        c = num1Inside + num2Inside;
    }

    let expression = `x^{2} ${b > 0 ? '+'+b+'x' : b+'x'} ${c > 0 ? '+'+c : c}`;
    if (c === 0) {
        expression = `x^{2} ${b > 0 ? '+'+b+'x' : b+'x'}`;
    }
    if (b === 0) {
        expression = `x^{2} ${c > 0 ? '+'+c : c}`;
    }

    question = `Solve the equation ~~${expression}=0~~`;
    answer = `x=${-num1Inside},x=${-num2Inside}`;
    answerLatex = answer;

    steps = `
        Start by factorising the expression to get (x${num1Inside > 0 ? '+'+num1Inside : num1Inside})(x${num2Inside > 0 ? '+'+num2Inside : num2Inside})=0.
        Then for each bracket, make the bracket equal to 0. From there solve the equation to get x by itself.
        From there you should get the answers: ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function SimultaneousEquations() {
    let question, answer, answerLatex, steps;

    let numMin = -10;
    let numMax = 10;

    let x = getRandomInt(numMin,numMax);
    let y = getRandomInt(numMin,numMax);

    let x1Num = getRandomInt(numMin,numMax);
    let y1Num = getRandomInt(numMin,numMax);

    while (x1Num === 0) {
        x1Num = getRandomInt(numMin,numMax);
    }
    while (y1Num === 0) {
        y1Num = getRandomInt(numMin,numMax);
    }

    let equation1Equals = (x1Num*x) + (y1Num*y);


    let x2Num = getRandomInt(numMin,numMax);
    let y2Num = getRandomInt(numMin,numMax);
    while ((x2Num === x1Num) && (y2Num === y1Num)) {
        x2Num = getRandomInt(numMin,numMax);
        y2Num = getRandomInt(numMin,numMax);
    }
    while (x2Num === 0) {
        x2Num = getRandomInt(numMin,numMax);
    }
    while (y2Num === 0) {
        y2Num = getRandomInt(numMin,numMax);
    }

    let equation2Equals = (x2Num*x) + (y2Num*y);

    let equation1 = `${x1Num}x${y1Num > 0 ? '+'+y1Num : y1Num}y=${equation1Equals}`;
    let equation2 = `${x2Num}x${y2Num > 0 ? '+'+y2Num : y2Num}y=${equation2Equals}`;

    question = `Solve the simultaneous equations: ~~${equation1}\\newline${equation2}~~`;
    answer = `x=${x},y=${y}`;
    answerLatex = answer;

    question = question.trim().split(/[\s\t\n]+/).join(' ');

    steps = `
        To use the elimination method you need to get rid of one of the variables.
        To do this multiply the number in front of the y's by the other equation.
        So ${equation1} multiplied by ${y2Num} and ${equation2} multiplied by ${y1Num}.
        The first equation should be ${x1Num*y2Num}x${y1Num*y2Num > 0 ? '+'+y1Num*y2Num : y1Num*y2Num}y=${equation1Equals*y2Num}.
        The second equation should be ${x2Num*y1Num}x${y2Num*y1Num > 0 ? '+'+y2Num*y1Num : y2Num*y1Num}y=${equation2Equals*y1Num}.
        After this take away the first equation from the second equation to get: ${(x2Num*y1Num)-(x1Num*y2Num)}x=${(equation2Equals*y1Num)-(equation1Equals*y2Num)}.
        Now solve this equation to get x=${x}. You can now substitute x into either of the two starting equations to find what y is.
    `;

    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function Inequalities() {
    let question, answer, answerLatex, steps;

    let coefficient = getRandomInt(-10,10);
    while (coefficient === 0) {
        coefficient = getRandomInt(-10,10);
    }
    answer = getRandomInt(2,50);
    const rightSide = coefficient*answer;
    let leftSideNumber = getRandomInt(-25,25);
    while (leftSideNumber === 0) {
        leftSideNumber = getRandomInt(-25,25);
    }
    const signs = ['<','>'];
    let signIndex = getRandomInt(0,signs.length-1);
    let sign = signs[signIndex];

    question = `${coefficient}x ${leftSideNumber > 0 ? '+'+leftSideNumber : leftSideNumber} ${sign} ${rightSide+leftSideNumber}`;

    let signIndexTemp = signIndex;
    let flippedText = '';
    if (coefficient < 0) {
        signIndexTemp += 1;
        if (signIndexTemp > signs.length-1) {
            signIndexTemp = 0;
        }
        flippedText = 'Since you are dividing by a negative number, remember to flip the inequality.';
    }

    if (leftSideNumber > 0) {
        steps = `
        To solve the equation ${question}, the goal is to find out what x is. 
        Start by getting rid of the ${leftSideNumber} on the left side. 
        To do that, subtract ${leftSideNumber} from both sides of the equation, which gives ${coefficient}x ${sign} ${rightSide} + ${leftSideNumber}, simplifying to ${coefficient}x ${sign} ${rightSide+leftSideNumber}. 
        Now, to isolate x, divide both sides by ${coefficient}: x÷${coefficient}${sign}${rightSide+leftSideNumber}÷${coefficient}. ${flippedText}
        Simplifying this gives x${signs[signIndexTemp]}${answer}. So, the solution is x${signs[signIndexTemp]}${answer}.
        `;
    }
    if (leftSideNumber < 0) {
        steps = `
        To solve the equation ${question}, the goal is to find out what x is. 
        Start by getting rid of the ${leftSideNumber} on the left side. 
        To do that, add ${leftSideNumber} from both sides of the equation, which gives ${coefficient}x ${sign} ${rightSide} + ${leftSideNumber}, simplifying to ${coefficient}x ${sign} ${rightSide+leftSideNumber}. 
        Now, to isolate x, divide both sides by ${coefficient}: x÷${coefficient}${sign}${rightSide+leftSideNumber}÷${coefficient}. ${flippedText}
        Simplifying this gives x${signs[signIndexTemp]}${answer}. So, the solution is x${signs[signIndexTemp]}${answer}.
        `;
    }

    question = `Solve ~${question}~`;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[`x=${answer}`,`~x${signs[signIndexTemp]}${answer}~`],steps];
}

function SimplifyRatios() {
    let question, answer, steps;

    let answerLow = getRandomInt(1, 9);
    let answerOther = answerLow * getRandomInt(2, 12);

    // Find the HCF of answerLow and answerOther to simplify the ratio
    let hcf = 1;
    for (let i = 1; i <= answerLow && i <= answerOther; i++) {
        if (answerLow % i === 0 && answerOther % i === 0) {
            hcf = i;
        }
    }

    // Simplify the ratio by dividing both numbers by the HCF
    let simplifiedLow = answerLow;
    let simplifiedOther = answerOther;

    if (hcf !== 1) {
        simplifiedLow = answerLow / hcf;
        simplifiedOther = answerOther / hcf;
    }

    const common = getRandomInt(1,12);
    const combinedHCF = hcf * common;

    question = `Write the ratio ${answerLow*common}:${answerOther*common} in its simplest form.`;
    answer = `${simplifiedLow}:${simplifiedOther}`;

    steps = `To simplify the ratio ${answerLow*common}:${answerOther*common}, you need to find the highest factor that goes into both the numbers in the ratio.
            The highest number is ${combinedHCF}.
            After finding that, divide both parts of the ratio by ${combinedHCF}:
            ${answerLow} ÷ ${combinedHCF} = ${simplifiedLow},
            ${answerOther} ÷ ${combinedHCF} = ${simplifiedOther}.
            Now the simplest form of the ratio is ${answer}.`;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function FindingQuantitiesRatios() {
    let question, answer, steps;

    const names = ["Will", "Olly", "Sam", "Alex", "Jess", "Taylor"];
    const name1 = names[Math.floor(Math.random() * names.length)];
    let name2 = names[Math.floor(Math.random() * names.length)];
    if (name2 === name1) {
        name2 = names[Math.floor(Math.random() * names.length)];
    }

    let ratio1 = getRandomInt(1,10);
    let ratio2 = getRandomInt(1,10);
    while (ratio1 === ratio2) {
        ratio2 = getRandomInt(1,10);
    }

    let unitAmount = getRandomInt(2,100);
    let totalAmount = unitAmount * (ratio1 + ratio2);

    let personNum = getRandomInt(0,1);
    let person = personNum ? name2 : name1;

    question = `${name1} and ${name2} share £${totalAmount} in the ratio ${ratio1}:${ratio2}. Work out how much ${person} gets.`;

    answer = personNum ? ((totalAmount * ratio2) / (ratio1+ratio2)) : ((totalAmount * ratio1) / (ratio1+ratio2));
    answer = `£${answer}`;

    steps = `
        To find the amount of money that ${person} gets, you have to start by adding the ratios together: ${ratio1}+${ratio2}=${ratio1+ratio2}.
        Now divide the ${totalAmount} by ${ratio1+ratio2} to find out how much one part is: ${totalAmount}÷${ratio1+ratio2}=£${unitAmount}.
        Once you have done that, multiply the value of one part by the ratio for ${person} which is ${personNum ? ratio2 : ratio1} multiplied by ${unitAmount}.
        That means you should get the answer of ${answer}.
        `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function Ratio() {
    let question, answer, answerLatex, steps;

    // Example question
    // In a bag there are blue and red sweets.
    // The ratio of blue to red sweets is 5:3
    // What fraction of the sweets are blue

    const colours = ['blue','red', 'green', 'yellow', 'purple', 'black', 'pink'];
    let colour1 = colours[getRandomInt(0,colours.length-1)];
    let colour2 = colours[getRandomInt(0,colours.length-1)];
    while (colour1 === colour2) {
        colour2 = colours[getRandomInt(0,colours.length-1)];
    }

    let ratio1 = getRandomInt(1,12);
    let ratio2 = getRandomInt(1,12);
    while (ratio2 === ratio1) {
        ratio2 = getRandomInt(1,12);
    }

    let colourChoice = getRandomInt(0,1);

    question = `
        In a bag there are ${colour1} and ${colour2} sweets. 
        The ratio of ${colour1} to ${colour2} sweets is ${ratio1}:${ratio2}.
        What fraction of the sweets are ${colourChoice ? colour2 : colour1}?
    `;

    answer = colourChoice ? `${ratio2}/${ratio1+ratio2}` : `${ratio1}/${ratio1+ratio2}`;
    answerLatex = colourChoice ? `\\frac{${ratio2}}{${ratio1+ratio2}}` : `\\frac{${ratio1}}{${ratio1+ratio2}}`;

    steps = `
        Start by identifying which part of the ratio is for the ${colourChoice ? colour2 : colour1} sweets.
        In this case it is the ${colourChoice ? ratio2 : ratio1} part of the ratio.
        After that add together the ratios: ${ratio1}+${ratio2}=${ratio1+ratio2}.
        Once you have done that, put the part of the ratio found before over the ratios added together to get: ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function Proportion() {
    let question, answer, steps;

    // Here is a list of ingredients for making 10 flapjacks:
    // 80g rolled oats, 60g butter, 30ml golden syrup, 36g light brown sugar
    // Work out the amount of ingredients needed to make 15 flapjacks

    let ingredients = [];
    ingredients.push([Math.round(getRandomInt(50,300) * 10) / 10, 'rolled oats', 'g']); // rolled oats
    ingredients.push([Math.round(getRandomInt(50,300) * 10) / 10, 'butter', 'g']); // butter
    ingredients.push([Math.round(getRandomInt(10,50) * 10) / 10, 'golden syrup', 'ml']); // golden syrup
    ingredients.push([Math.round(getRandomInt(5,50) * 10) / 10, 'light brown sugar', 'g']); // light brown sugar

    const ingredientToCalculate = getRandomInt(0,3);

    let amount = getRandomInt(5,30);
    while (amount === 10) {
        amount = getRandomInt(5,30);
    }

    let original = getRandomInt(5,30);
    while (original === 10) {
        original = getRandomInt(5,30);
    }

    question = `
        Here is a list of ingredient for making ${original} flapjacks:
        ${ingredients[0][0]}g rolled oats, ${ingredients[1][0]}g butter, ${ingredients[2][0]}ml golden syrup, ${ingredients[3][0]}g light brown sugar.
        Work out how much ${ingredients[ingredientToCalculate][1]} you need to make ${amount} flapjacks.
    `;

    answer = (ingredients[ingredientToCalculate][0]/original)*amount;
    answer = Math.round(answer * 100) / 100;
    answer = `${answer}${ingredients[ingredientToCalculate][2]}`;

    steps = `
        To calculate how to make ${amount} flapjacks, you need to find how much ${ingredients[ingredientToCalculate][1]} you need to make one flapjack.
        To do that you can divide ${ingredients[ingredientToCalculate][0]} by ${amount} to find out how much ${ingredients[ingredientToCalculate][1]} you need.
        That should get you ${Math.round(ingredients[ingredientToCalculate][0]/original * 100) / 100}${ingredients[ingredientToCalculate][2]}.
        From there you can multiply that ${Math.round(ingredients[ingredientToCalculate][0]/original * 100) / 100}${ingredients[ingredientToCalculate][2]} by ${amount} to find out how much you would need for ${amount} flapjacks.
        That should get ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function DirectInverseProportion() {
    let question, answer, steps;

    steps = '';

    const num_workers = getRandomInt(2, 10);
    const time_with_workers = getRandomInt(2, 7);
    const num_sheets = 500;
    const sheet_weight = 2;
    const smaller_num_sheets = getRandomInt(50, 250, 50);
    const cars = getRandomInt(4, 20);
    const fuel = cars * getRandomInt(1, 5);
    const time_traveled = getRandomInt(1, 5);
    const speed = getRandomInt(30, 90, 10);
    const loaves = num_workers * getRandomInt(10, 50);
    const fences = num_workers * getRandomInt(5, 15);
    const fruit = num_workers * getRandomInt(50, 200);
    const bike_distance = getRandomInt(150, 600, 150);
    const bike_fuel = (bike_distance / 150) * 3;

    const answer_inverse = num_workers * time_with_workers;
    const answer_weight = (smaller_num_sheets / num_sheets) * sheet_weight;
    const answer_fuel_per_car = fuel / cars;
    const answer_distance = speed * time_traveled;
    const answer_double_speed_time = time_traveled / 2;
    const answer_harvest_double = fruit * 2;

    const question_templates = [
        `It takes ${num_workers} painters ${time_with_workers} days to complete a job. Work out how many days it would take 1 painter to complete the same job.`,
        `${num_workers} machines take ${time_with_workers} hours to produce a batch of goods. Work out how many hours it would take 1 machine to produce the same batch of goods.`,
        `There are ${num_sheets} sheets in a pack of paper. ${num_sheets} sheets of paper weigh ${sheet_weight}kg. Work out the weight of ${smaller_num_sheets} sheets of paper.`,
        `${cars} cars travel a total of ${fuel} litres of fuel. Work out the amount of fuel used by each car on average.`,
        `A car travels at a speed of ${speed} miles per hour. How far will it travel in ${time_traveled} hours?`,
        `${num_workers} bakers can bake ${loaves} loaves of bread in ${time_with_workers} hours. Work out how many loaves of bread 1 baker can bake in the same time.`,
        `${num_workers} people can paint ${fences} fences in ${time_with_workers} days. Work out how many fences ${num_workers + 2} people can paint in ${time_with_workers} days.`,
        `${num_workers} people harvest ${fruit} kilograms of fruit in ${time_with_workers} days. How much fruit can ${num_workers * 2} people harvest in the same time?`,
        `A car traveling at ${speed} km/h takes ${time_traveled} hours to complete a journey. Work out the time it would take if the car traveled at double the speed.`,
        `A bike uses 3 litres of fuel to travel 150 km. How many liters of fuel would it need to travel ${bike_distance} km?`,
    ];

    const selected_template_index = getRandomInt(0, question_templates.length - 1);
    question = question_templates[selected_template_index].trim();

    switch (selected_template_index) {
        case 0:
        case 1:
            answer = `${answer_inverse}`;
            steps = `
                For inverse proportion, if ${num_workers} workers take ${time_with_workers} days, then 1 worker will take ${num_workers} × ${time_with_workers} days. 
                Multiply ${num_workers} by ${time_with_workers}, which equals ${answer_inverse}.
                Therefore, it would take ${answer_inverse} days.
            `.trim();
            break;
        case 2:
            answer = `${answer_weight} kg`;
            steps = `
                Find the weight of one sheet by dividing ${sheet_weight}kg by ${num_sheets} sheets: ${sheet_weight} ÷ ${num_sheets} = ${(sheet_weight / num_sheets).toFixed(4)}kg.
                Multiply the weight of one sheet by ${smaller_num_sheets} sheets: ${(sheet_weight / num_sheets).toFixed(4)} × ${smaller_num_sheets} = ${answer_weight}kg.
                Therefore, the weight of ${smaller_num_sheets} sheets is ${answer_weight}kg.
            `.trim();
            break;
        case 3:
            answer = `${answer_fuel_per_car} litres`;
            steps = `
                Divide the total fuel (${fuel} litres) by the number of cars (${cars} cars): ${fuel} ÷ ${cars} = ${answer_fuel_per_car} litres.
                Therefore, each car uses ${answer_fuel_per_car} litres of fuel on average.
            `.trim();
            break;
        case 4:
            answer = `${answer_distance} miles`;
            steps = `
                Use the formula distance = speed × time. Multiply the speed (${speed} miles per hour) by the time (${time_traveled} hours): ${speed} × ${time_traveled} = ${answer_distance}.
                Therefore, the car will travel ${answer_distance} miles.
            `.trim();
            break;
        case 5:
            answer = `${loaves / num_workers}`;
            steps = `
                Divide the total number of loaves (${loaves}) by the number of bakers (${num_workers}): ${loaves} ÷ ${num_workers}.
                This gives ${loaves / num_workers} loaves per baker.
            `.trim();
            break;
        case 6:
            answer = `${fences * (num_workers + 2) / num_workers}`;
            steps = `
                Multiply the number of people (${num_workers + 2}) by the number of fences painted by one person (${fences / num_workers} fences): (${num_workers + 2}) × (${fences / num_workers}).
                This gives ${(fences / num_workers) * (num_workers + 2)} fences.
            `.trim();
            break;
        case 7:
            answer = `${answer_harvest_double} kg`;
            steps = `
                If ${num_workers} people harvest ${fruit} kg, then ${num_workers * 2} people will harvest double: ${fruit} × 2 = ${answer_harvest_double} kg.
                Therefore, the amount harvested is ${answer_harvest_double} kg.
            `.trim();
            break;
        case 8:
            answer = `${answer_double_speed_time} hours`;
            steps = `
                Time is inversely proportional to speed. Doubling the speed halves the time. Original time: ${time_traveled} hours. Halved time: ${answer_double_speed_time} hours.
                Therefore, the journey will take ${answer_double_speed_time} hours.
            `.trim();
            break;
        case 9:
            answer = `${bike_fuel} litres`;
            steps = `
                For 150 km, 3 litres of fuel are used. Scale this up for ${bike_distance} km: (${bike_distance} ÷ 150) × 3 = ${bike_fuel}.
                Therefore, the bike needs ${bike_fuel} litres of fuel.
            `.trim();
            break;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function Speed() {
    let question, answer, steps;

    const type = getRandomInt(0,2);

    // Finding the speed
    if (type === 0) {
        answer = getRandomInt(1,100); // speed*time = distance
        let time = Math.round(getRandomInt(10,100) * 10) / 10;
        let unit = 's';
        let distance = answer*time;

        question = `
            An object moves ${distance}m in ${time}${unit}. Work out the speed of the object.
        `;

        answer = `${answer}m/s`;
        steps = `
            Identify the distance and the time. In this case the distance is ${distance} and the time is ${time}.
            Remember the equation for speed is distance divided by time. We are just going to use that equation now.
            Start by dividing ${distance} by ${time} to get the speed: ${distance} ÷ ${time} = ${answer}.
            One important thing is to always remember the unit.
        `;
    }

    // Finding the time
    if (type === 1) {
        let speed = getRandomInt(1,100); // time = distance/speed
        let time = Math.round(getRandomInt(10,100) * 10) / 10;
        let distance = speed*time;

        question = `
            An object moves ${speed}m/s for ${distance}m. Work out how long the object was moving at that speed for.
        `;

        answer = time;
        answer = `${answer}s`;
        steps = `
            Identify the distance and the speed. In this case the distance is ${distance} and the speed is ${speed}.
            Remember the equation for speed is distance divided by time. We are just going to use that equation now.
            We are going to have to rearrange the equation to get time. 
            The equation to find the time from the speed and the distance is: time = distance ÷ speed
            Start by dividing ${distance} by ${speed} to get the time: ${distance} ÷ ${speed} = ${answer}.
            One important thing is to always remember the unit.
        `;
    }

    // Finding the distance
    if (type === 2) {
        let speed = getRandomInt(1,100);
        let time = Math.round(getRandomInt(10,100) * 10) / 10;
        let distance = speed*time;

        question = `
            An object moves ${speed}m/s for ${time}s. Work out how far the object went.
        `;

        answer = distance;
        answer = `${answer}m`;
        steps = `
            Identify the time and the speed. In this case the time is ${time} and the speed is ${speed}.
            Remember the equation for speed is distance divided by time. We are just going to use that equation now.
            We are going to have to rearrange the equation to get distance. 
            The equation to find the distance from the speed and the time is speed multiplied by time.
            Start by multiplying ${speed} by ${time} to get the distance: ${time} multiplied by ${speed} = ${answer}.
            One important thing is to always remember the unit.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function Probability() {
    let question, answer, steps;

    /* 
    There are 53 counters in a bag
    15 of the counters are red.
    The rest of the counters are blue.
    One of the counters is taken at random.
    Find the probability that the counter is blue.
    */

    const amount = getRandomInt(10,100);
    const red = getRandomInt(1,amount-1);
    const blue = amount - red;

    const colourToFind = getRandomInt(0, 1)

    question = `
        There are ${amount} counters in a bag.
        ${red} of the counters are red.
        The rest of the counters are blue.
        One of the counters is taken at random.
        Find the probability that the counter is ${colourToFind ? 'blue': 'red'}.
    `;

    answer = colourToFind ? `${blue}/${amount}` : `${red}/${amount}`;

    steps = `
        To solve this question, you need to figure out how many ${colourToFind ? 'blue': 'red'} counters there are.
        This means the answer is ${answer} as that is the likelihood of finding one of those counters in the bag.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}