import Fraction from 'fraction.js';
import { evaluate, sqrt, isPrime, simplify, format, add } from 'mathjs';
import { expand } from 'algebrite';
import { getRandomFloat, getRandomInt, primeFactors } from './GCSEQuestionGenerator';

const names = [
    ["William",
    "George",
    "Thomas",
    "Henry",
    "Harry",
    "James",
    "Matthew",
    "Fred",
    "Alfred",
    "Charlie",
    "Charles",
    "Alfie",
    "Freddie",
    "Aaron",
    "Bradley",
    "Dean",
    "David",
    "Kyle",
    "Luke",
    "Terry",
    "Tom",
    "Oliver",
    "Ollie",
    ],
    [
    "Alice",
    "Florence",
    "Elsie",
    "Ivy",
    "Violet",
    "Lily",
    "Gladys",
    "Doris",
    "Hilda",
    "Ethel",
    "Abbie",
    "Eve",
    "Claire",
    "Freya",
    "Lucy",
    "Amy",
    "Emma",
    "Emily",
    "Lydia",
    "Martha",
    "Eloise",
    "Lottie",
    "Olivia",
    "Kez",
    "Maddie",
    "Megan",
    "Phoebe",
    "Susan",
    "Suzanne",
    "Sophie",
    "Tanya",
    "Victoria",
    "Zoe"
]];

export const topicDecisionHigher = (topic) => {
    switch (topic) {
        case 'surds':
            return Surds();
        case 'product_rule_counting':
            return ProductRuleCounting();
        case 'repeat_percentage_change':
            return RepeatedPercentageChange();
        case 'expand_triple_brackets':
            return TripleBrackets();
        case 'bounds':
            return Bounds();
        case 'quadratic_formula':
            return QuadraticFormula();
        case 'solving_equations_iteration':
            return SolvingEquationsIteration();
        case 'frac_neg_indices':
            return FractionalNegativeIndices();
        case 'par_perp_lines':
            return ParallelPerpendicularLines();
        case 'direct_inverse_proportion_higher':
            return DirectInverseProportion();
        case 'factorising_harder_quadratics':
            return FactorisingHarderQuadratics();
        case 'inverse_composite_functions':
            return InverseCompositeFunctions();
        case 'complete_the_square':
            return CompleteTheSquare();
        case 'quadratic_inequalities':
            return QuadraticInequalities();
        /*case 'probability_equations':
            return ProbabilityEquations();*/
        default:
            return ['blank',['blank','[blank]'],'blank'];
    }
}

function Surds() {
    let num = getRandomInt(2,30);
    
    // checking if the number can be broken down into a surd
    let numPrimes = primeFactors(num);
    for (let i=0; i < numPrimes.length; i++) {
        if (!Number.isInteger(sqrt(numPrimes[i]))) {
            num = numPrimes[i];
            break;
        }
    }

    // generating a square number
    const answerNum = getRandomInt(2,14);
    const answerSquared = answerNum * answerNum;

    const answerLatex = `~${answerNum}\\sqrt{${num}}~`;

    const question = `Write the surd ~\\sqrt{${num*answerSquared}}~ in the form of ~k\\sqrt{${num}}~, where ~k~ is an integer.`;
    const steps = `Start off by finding a square number that is a factor of ${num*answerSquared}. 
    Now rewrite that surd as a product of this square number and another number, then evaluate the root of the square number.
    Repeat this until the number under the root has no square factors left.`;

    return [question, [`${answerNum}sqrt(${num})`,answerLatex], steps];
}

function ProductRuleCounting() {
    let question, answer, steps;

    /*
    Example Question:
    There are 12 boys and 15 girls in a class.
    One boy and one girl will be selected to represent the class on the student council. 
    Work out the total number of ways of choosing a boy and a girl.

    Answer: 12 * 15 = 180
     */

    const random_number1 = getRandomInt(5,30);
    const random_number2 = getRandomInt(5,30);
    const question_template = [
            `
            There are ${random_number1} boys and ${random_number2} girls in a class.
            One boy and one girl will be selected to represent the class on the student council.
            Work out the total number of ways of choosing a boy and a girl.
            `,
            `
            There are ${random_number1} boys and ${random_number2} girls in a choir.
            One boy and one girl will be selected to sing a duet.
            Work out the total number of ways of choosing a boy and a girl.
            `,
            `
            There are ${random_number1} starters and ${random_number2} main courses in a restaurant.
            Work out the total number of ways of choosing a starter and a main course.
            `,
            `
            There are ${random_number1} main courses and ${random_number2} desserts in a restaurant.
            Work out the total number of ways of choosing a main course and a dessert.
            `,
            `
            A meal deal includes a sandwich and a drink.
            There are ${random_number1} sandwiches and ${random_number2} drinks to choose from.
            Work out the total number of ways of choosing a sandwich and a drink.
            `,
            `
            A meal deal includes a sandwich and a snack.
            There are ${random_number1} sandwiches and ${random_number2} snacks to choose from.
            Work out the total number of ways of choosing a sandwich and a snack.
            `,
            `
            In a school there are ${random_number1} in one year group and ${random_number2} in the year group above.
            The headteacher wants to interview one student from each class.
            Work out the total number of ways of choosing a student from each class.
            `,
            `
            There are ${random_number1} shirts and ${random_number2} trousers in a wardrobe.
            One shirt and one pair of trousers will be chosen for an outfit.
            Work out the total number of ways of choosing a shirt and a pair of trousers.
            `,
            `
            A group of friends are wanting to watch two movies for a movie night. 
            There are ${random_number1} action movies and ${random_number2} comedy movies available.
            They want to choose one movie of each genre for the movie night.
            Work out the total number of ways of choosing an action movie and a comedy movie.
            `
    ];

    question = question_template[getRandomInt(0,question_template.length-1)];
    answer = random_number1 * random_number2;
    steps = `
    To calculate the answer, you need to find the total number of combinations. 
    To find the answer you can multiply ${random_number1} by ${random_number2}.
    This should get you ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function RepeatedPercentageChange() {
    let question, answer, steps;

    steps = '';

    answer = getRandomInt(10,1000);
    const percentage1 = getRandomInt(1,30);
    const percentage2 = getRandomInt(1,30);
    const time = getRandomInt(3,10);
    const name_gender = getRandomInt(0,1);
    const name = names[name_gender][getRandomInt(0,names[name_gender].length-1)];

    let amount = answer * (((percentage1+100)/100)**time);
    amount = Math.round((amount)*100)/100;

    let amount2 = 0;

    const question_template_length = 2;
    const question_template_choice = getRandomInt(0,question_template_length-1);
    if (question_template_choice === 1) {
        amount = Math.round((getRandomInt(5,1000) / 10))*10;
        amount2 = amount * (((percentage1+100)/100)); 
        amount2 = amount2 * (((percentage2+100)/100)**(time-1));
        answer = percentage2;

        amount = Math.round((amount)*100)/100;
        amount2 = Math.round((amount2)*100)/100;
    }

    const question_template = [
        `
        ${name} invests some money for ${time} years in a savings account.
        ${name_gender === 0 ? 'He' : 'She'} gets ${percentage1}% per annum compound interest.
        ${name} has £${amount} at the end of the ${time} years, work out how much she invested. Round your answer to the nearest pound.
        `,
        `
        ${name} invests £${amount} for ${time} years in a savings account.
        ${name_gender === 0 ? 'He' : 'She'} gets ${percentage1}% per annum compound interest in the first year, then ~x~% for ${time-1} years.
        ${name} has £${amount2} at the end of ${time} years, work out the value of ~x~. Round your answer to the nearest whole number.
        `
    ];

    question = question_template[question_template_choice];
    if (question_template_choice === 0) answer = `£${answer}`;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function TripleBrackets() {
    let question, answer, answerLatex, steps;

    /*
     Example Question:
     Expand and Simplify (x+2)(x+4)(x+1) 
     */

    let num1 = getRandomInt(-10,10);
    while (num1 === 0) {
        num1 = getRandomInt(-10,10);
    }
    let num2 = getRandomInt(-10,10);
    while (num2 === 0) {
        num2 = getRandomInt(-10,10);
    }
    let num3 = getRandomInt(-10,10);
    while (num3 === 0) {
        num3 = getRandomInt(-10,10);
    }

    question = `Expand ~(x${num1 > 0 ? `+${num1}` : `${num1}`})(x${num2 > 0 ? `+${num2}` : `${num2}`})(x${num3 > 0 ? `+${num3}` : `${num3}`})~`

    let coff1 = num1+num2+num3;
    let coff2 = ((num1+num2)*num3)+((num1*num2));
    let coff3 = num1*num2*num3;
    answer = `x^3${(coff1 > 0) && (coff1 !== 0) ? `+${coff1}x^2` : `${coff1}x^2`}${(coff2 > 0) && (coff2 !== 0) ? `+${coff2}x` : `${coff2}x`}${(coff3 > 0) && (coff3 !== 0) ? `+${coff3}` : `${coff3}`}`
    answerLatex = answer;

    steps = `
        To solve this question, start by expanding two brackets at a time. 
        First, expand and simplify (x${num1 > 0 ? `+${num1}` : num1})(x${num2 > 0 ? `+${num2}` : num2}) to get x^2${(num1 + num2) !== 0 ? (num1 + num2 > 0 ? `+${num1 + num2}` : num1 + num2) : ''}x${(num1 * num2) !== 0 ? (num1 * num2 > 0 ? `+${num1 * num2}` : num1 * num2) : ''}.
        Then, multiply this result by (x${num3 > 0 ? `+${num3}` : num3}), expanding each term to get ${answer} as your final answer.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function Bounds() {
    let question, answer, steps;

    const length = getRandomInt(5,200);
    const width = getRandomInt(5,200);
    const bound_choice = getRandomInt(0,1); // 0 = upper bound, 1 = lower bound

    let num1, num2;

    const question_template_num = getRandomInt(0,3);

    if (question_template_num === 2) {
        num1 = getRandomFloat(1,20);
        num2 = getRandomFloat(1,num1-1);
        num1 = Math.round(num1 * 100) / 100;
        num2 = Math.round(num2 * 1000) / 1000;
    }
    if (question_template_num === 3) {
        num1 = getRandomFloat(1,20);
        num2 = getRandomFloat(1,20);
        num1 = Math.round(num1 * 100) / 100;
        num2 = Math.round(num2 * 1000) / 1000;
    }

    const question_templates = [
        `
        A rectangle has a length of ${length}cm, to the nearest cm, and a width of ${width}cm, to the nearest cm.
        Work out the ${bound_choice ? 'lower bound' : 'upper bound'} for the perimeter of the rectangle.
        `,
        `
        A rectangle has a length of ${length}cm, to the nearest cm, and a width of ${width}cm, to the nearest cm.
        Work out the ${bound_choice ? 'lower bound' : 'upper bound'} for the area of the rectangle.
        `,
        `
        An equation is shown below.
        s = ${num1} correct to 2 decimal places.
        t = ${num2} correct to 3 decimal places.
        Work out the ${bound_choice ? 'lower bound' : 'upper bound'} for v.
        Give your answer to 3 decimal places.
        ~~v=\\frac{s}{t}~~
        `,
        `
        An equation is shown below.
        I = ${num1} correct to 2 decimal places.
        R = ${num2} correct to 3 decimal places.
        Work out the ${bound_choice ? 'lower bound' : 'upper bound'} for V.
        Give your answer to 3 decimal places.
        ~~V=IR~~
        `
    ];

    question = question_templates[question_template_num];

    if (question_template_num === 0) { // perimeter
        switch (bound_choice) {
            case 0:
                answer = ((length+0.5)*2)+((width+0.5)*2);
                break;
            case 1:
                answer = ((length-0.5)*2)+((width-0.5)*2);
                break;
            default:
                break;
        }
        answer = `${answer}cm`;
        steps = `
            To find the perimeter you need to find both ${bound_choice ? 'lower bounds' : 'upper bounds' } of the length and width by ${bound_choice ? 'subtracting' : 'adding' } 0.5 (half the unit) to the given measurements. 
            Once you have found that, use 2 multiplied by the (Length+Width) to find the final answer for the perimeter which equals ${answer}.
        `;
    }

    if (question_template_num === 1) { // area
        switch (bound_choice) {
            case 0:
                answer = (length+0.5)*(width+0.5);
                break;
            case 1:
                answer = (length-0.5)*(width-0.5);
                break;
            default:
                break;
        }
        answer = `${answer}cm^2`;
        steps = `
            To find the area you need to find the ${bound_choice ? 'lower bounds' : 'upper bounds' } of the length and width by ${bound_choice ? 'subtracting' : 'adding' } 0.5 (half the unit) to the given measurements. 
            Once you have found that, multiply both those values to find the final answer for the area which equals ${answer}.
        `;
    }
    
    if (question_template_num === 2) { // division
        switch (bound_choice) {
            case 0:
                answer = (num1+0.005)/(num2-0.0005);
                answer = Math.round(answer * 1000) / 1000;
                steps = `
                    To find this answer, you need to start by finding the upper bound for s and the lower bound for t.
                    That should get you ${Math.round((num1+0.005) * 1000) / 1000} and ${Math.round((num2-0.0005)*10000)/10000}. After that you can divide ${Math.round((num1+0.005) * 1000) / 1000} by ${Math.round((num2-0.0005)*10000)/10000}.
                    This should get you ${answer}.
                `;
                break;
            case 1:
                answer = (num1-0.005)/(num2+0.0005);
                answer = Math.round(answer * 1000) / 1000;
                steps = `
                    To find this answer, you need to start by finding the lower bound for s and the upper bound for t.
                    That should get you ${Math.round((num1-0.005) * 1000) / 1000} and ${Math.round((num2-0.0005)*10000)/10000}. After that you can divide ${Math.round((num1-0.005) * 1000) / 1000} by ${Math.round((num2-0.0005)*10000)/10000}.
                    This should get you ${answer}.
                `;
                break;
            default:
                break;
        }
    }

    if (question_template_num === 3) { // multiplication
        switch (bound_choice) {
            case 0:
                answer = (num1+0.005)*(num2+0.0005);
                answer = Math.round(answer * 1000) / 1000;
                steps = `
                    To find this answer, you need to start by finding the upper bound for I and the upper bound for R.
                    That should get you ${Math.round((num1+0.005) * 1000) / 1000} and ${Math.round((num2+0.0005)*10000)/10000}. After that you can multiply ${Math.round((num1+0.005) * 1000) / 1000} by ${Math.round((num2+0.0005)*10000)/10000}.
                    This should get you ${answer}.
                `;
                break;
            case 1:
                answer = (num1-0.005)*(num2-0.0005);
                answer = Math.round(answer * 1000) / 1000;
                steps = `
                    To find this answer, you need to start by finding the lower bound for I and the lower bound for R.
                    That should get you ${Math.round((num1-0.005) * 1000) / 1000} and ${Math.round((num2-0.0005)*10000)/10000}. After that you can multiply ${Math.round((num1-0.005) * 1000) / 1000} by ${Math.round((num2-0.0005)*10000)/10000}.
                    This should get you ${answer}.
                `;
                break;
            default:
                break;
        }
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function QuadraticFormula() {
    let question, answer, steps;

    let a = getRandomInt(1,5);
    let b = getRandomInt(-50,50);
    let c = getRandomInt(-50,50);

    while (((b**2)-(4*a*c) < 0)) { // checking if there is a solution
        c = getRandomInt(-50,50);
    }

    question = `
        Solve ~${a > 1 ? `${a}` : ''}x^{2}${b > 0 ? `+${b}x` : (b === 0 ? '':`${b}x`)}${c > 0 ? `+${c}` : (c === 0 ? '':`${c}`)} = 0~. Give your solutions correct to 2 decimal places.
    `;

    const discriminant = ((b**2)-(4*a*c));
    if (discriminant === 0) { // one root
        answer = (-b + (((b**2) - 4*a*c)**(1/2)))/(2*a);
        answer = Math.round(answer * 100) / 100;
        answer = `x=${answer}`;
    }
    if (discriminant > 0) {
        let root1 = (-b + (((b**2) - 4*a*c)**(1/2)))/(2*a);
        root1 = Math.round(root1 * 100) / 100;
        let root2 = (-b - (((b**2) - 4*a*c)**(1/2)))/(2*a);
        root2 = Math.round(root2 * 100) / 100;
        answer = `x=${root1},\\:x=${root2}`;
    }

    steps = `
        To solve this question, you need to identify what a, b, and c are in the equation above.
        From the equation you can find that a=${a}, b=${b}, and c=${c}. Substitute these values into the formula,
        and then find the solutions for x by finding both the + and - cases of the square root.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function SolvingEquationsIteration() {
    let question, answer, steps;

    let p0 = getRandomInt(5,500);
    let time = getRandomInt(3,10);
    let multiplier = Math.round(getRandomFloat(1,2) * 100)/100;
    let additive = getRandomInt(-20,20);
    while (additive === 0) {
        additive = getRandomInt(-20,20);
    }

    const question_templates = [
        `
        The number of rabbits in a field t days from now can be calculated using the equations below.
        Work out the number of rabbits in the garden ${time} days from now.
        ~~P_0 = ${p0},\\:P_{t+1} = ${multiplier}(P_t ${additive > 0 ? `+${additive}`:`${additive}`})~~
        `,
        `
        The number of people living in a town t years from now can be calculated using the equations below.
        Work out the number of people in the town ${time} years from now.
        ~~P_0 = ${p0},\\:P_{t+1} = ${multiplier}(P_t ${additive > 0 ? `+${additive}`:`${additive}`})~~
        `,
        `
        The number of foxes in a forest t months from now can be calculated using the equations below.  
        Work out the number of foxes in the forest ${time} months from now.  
        ~~F_0 = ${p0},\\:F_{t+1} = ${multiplier}(F_t ${additive > 0 ? `+${additive}` : `${additive}`})~~
        `,
        `
        The number of fish in a pond t weeks from now can be calculated using the equations below.  
        Work out the number of fish in the pond ${time} weeks from now.  
        ~~P_0 = ${p0},\\:P_{t+1} = ${multiplier}(P_t ${additive > 0 ? `+${additive}` : `${additive}`})~~
        `,
        `
        The energy output of a system t hours from now can be calculated using the equations below.  
        Work out the energy output after ${time} hours.  
        ~~E_0 = ${p0},\\:E_{t+1} = ${multiplier}(E_t ${additive > 0 ? `+${additive}` : `${additive}`})~~
        `
    ];

    let question_template_num = getRandomInt(0, question_templates.length-1);
    question = question_templates[question_template_num];

    answer = p0
    for (let i=0; i < time; i++) {
        answer = multiplier*(answer + additive);
    }
    answer = Math.round(answer);

    steps = `
    To solve these questions, start by identifying the initial value P0 and the formula to be used.
    Then, use the formula iteratively ${time} times to calculate the value, starting from P0 (${p0}) and applying the formula for each iteration on the new value.
    The answer for this question should turn out to be ${answer} when rounded to the nearest whole number.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function FractionalNegativeIndices() {
    let question, answer, answerLatex, steps;

    const types = ['fractional', 'negative', 'both'];
    const type = types[getRandomInt(0,types.length-1)];

    if (type === 'fractional') {
        let root = getRandomInt(2,5);
        let root_multiplier = getRandomInt(1,root-1);
        let num1 = getRandomInt(1,10);
        answer = num1**root_multiplier;
        question = `Find the value of ~${num1**root}^{\\frac{${root_multiplier}}{${root}}}~`;
        answerLatex = answer;

        steps = `
            To solve this question, you need to recognise ${root_multiplier}/${root} means to root by ${root}.
            Start off by rooting ${num1**root} by ${root} which should get you ${num1}.
            After that you have to perform ${num1} to the power of ${root_multiplier}.
            This should get you the final answer of ${answer};
        `;
    }
    if (type === 'negative') {
        let num = getRandomInt(1,10);
        let power = getRandomInt(-1,-5);
        question = `Find the value of ~${num}^{${power}}~`;
        answer = new Fraction(num**power);
        answerLatex = answer.toLatex();

        steps = `
            To solve this question, start off by finding the reciprocal of ${num} which should be 1/${num}.
            By doing that you remove the negative part of the indice. 
            Now you can perform ${num} to the power of ${power*-1} to find your final answer.
            The final answer should be ${answer.n}/${answer.d}.
        `;
    }
    if (type === 'both') {
        let nom = getRandomInt(2,10);
        let denom = getRandomInt(2,10);
        while (denom === nom) {
            denom = getRandomInt(2,10);
        }
        let power_top = getRandomInt(2,3);
        let power_bottom = getRandomInt(2,3);
        while (power_bottom === power_top) {
            power_bottom = getRandomInt(2,3);
        }
        let frac = new Fraction(nom**power_bottom, denom**power_bottom);
        
        question = `Find the value of ~(${frac.toLatex()})^{-\\frac{${power_top}}{${power_bottom}}}~`;

        let flipped_nom = frac.d;
        let flipped_denom = frac.n;

        flipped_nom = flipped_nom**(1/power_bottom);
        flipped_nom = flipped_nom**power_top;

        flipped_denom = flipped_denom**(1/power_bottom);
        flipped_denom = flipped_denom**power_top;
        
        let answerFraction = new Fraction(parseInt(flipped_nom), parseInt(flipped_denom))
        answerLatex = answerFraction.toLatex();
        answer = `${answerFraction.n}/${answerFraction.d}`;

        steps = `
            Start by flipping the fraction to remove the negative part of the fraction.
            Once you have done this, root both the nominator (${frac.d}) and denominator (${frac.n}) by ${power_bottom}.
            That should get you ${Math.round(frac.d**(1/power_bottom))} and ${Math.round(frac.n**(1/power_bottom))}.
            After finding that you should put both those numbers to the power of ${power_top}.
            This should get you the final answer of ${answer}.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function ParallelPerpendicularLines() {
    let question, answer, steps;

    /*
    Example Question (Parallel):
    Write down the equation of the line parallel to y=3x+2 which passes through (0,4)

    Answer: Gradient of line needs to be the same

    Example Question (Perpendicular):
    Write down the equation of a line perpendicular to y=(1/2)x-4 which passes through (0,7)

    Answer: Negative reciprocal of gradient
    */

    const type = getRandomInt(0,1);

    // Parallel Line
    if (type === 0) {
        let gradient = getRandomInt(-12,12);
        while (gradient === 0) {
            gradient = getRandomInt(-12,12);
        }
        const c = getRandomInt(-12,12);
        let pointx = getRandomInt(-10,10);
        let pointy = getRandomInt(-10,10);
        while (((gradient*pointx) + c) === pointy) {
            pointx = getRandomInt(-10,10);
        }

        let answer_c = pointy - (gradient*pointx);

        question = `
            Write down the equation of the line parallel to ~y=${gradient}x${c > 0 ? `+${c}` : (c === 0 ? '' : `${c}`)}~ which passes through (${pointx}, ${pointy})
        `;
        answer = `y=${gradient}x${answer_c > 0 ? `+${answer_c}` : (answer_c === 0 ? '' : `${answer_c}`)}`;
        steps = `
            To solve, recognize that parallel lines have the same slope, so the slope of the new line is ${gradient}.
            Substitute the point (${pointx}, ${pointy}) into the slope-intercept form y=mx+c to find c.
            This should get you ${pointy}=(${gradient})(${pointx})+c. Now simplify this to get ${pointy}=${gradient*pointx}+c.
            To find c now you can perform ${pointy}-${gradient*pointx}=c.
            Once c is calculated, the equation of th eline should be ${answer}.
        `;
    }

    if (type === 1) {
        let gradient = getRandomInt(-12,12);
        while (gradient === 0) {
            gradient = getRandomInt(-12,12);
        }
        const c = getRandomInt(-12,12);
        let pointx = getRandomInt(-10,10);
        let pointy = (gradient*pointx) + c;

        const neg_recip_gradient = new Fraction(-1, gradient);;
        const right_side = new Fraction(Math.round(neg_recip_gradient.n * pointx), neg_recip_gradient.d);
        let answer_c = new Fraction(pointy).sub(right_side);

        question = `
            Write down the equation of the line perpendicular to ~y=${gradient}x${c > 0 ? `+${c}` : (c === 0 ? '' : `${c}`)}~ which passes through (${pointx}, ${pointy})
        `;
        answer = `y=${neg_recip_gradient.toLatex()}x${answer_c.s > 0 ? `+${answer_c.toLatex()}` : (answer_c.s === 0 ? '' : `${answer_c.toLatex()}`)}`;
        steps = `
            To solve, recognize that perpendicular lines have the negative reciprocal of the original line, so the slope of the new line is ${neg_recip_gradient.s === -1 ? '-' : ''}${neg_recip_gradient.n}/${neg_recip_gradient.d}.
            Substitute the point (${pointx}, ${pointy}) into slope-intercept form y=mx+c to find c.
            This should get you ${pointy}=(${neg_recip_gradient.s === -1 ? '-' : ''}${neg_recip_gradient.n}/${neg_recip_gradient.d})(${pointx})+c. Now simplify this to get ${pointy}=${right_side.s === -1 ? '-' : ''}${right_side.n}/${right_side.d}+c.
            To find c now you can perform ${pointy}-(${right_side.s === -1 ? '-' : ''}${right_side.n}/${right_side.d})=c.
            Once c is calculated, the equation of the line should be y=${right_side.s === -1 ? '-' : ''}${neg_recip_gradient.n}/${neg_recip_gradient.d}x${answer_c.s === 1 ? '+' : (answer_c.s === -1 ? '-' : '')}${answer_c.n}/${answer_c.d}.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function DirectInverseProportion() {
    let question, answer, answerLatex, steps;

    const type = getRandomInt(0,3);

    // Directly proportional
    if (type === 0) {
        let num1 = getRandomInt(2,15);
        let num2 = num1*getRandomInt(2,15);
        let question_num = getRandomInt(2,15);
        while (question_num === num1) {
            question_num = getRandomInt(2,15);
        }
        question = `
            a is directly proportional to b. When a = ${num1}, b = ${num2}.
            Find the value of b when a = ${question_num}.
        `;

        answer = (num2/num1)*question_num;
        answerLatex = answer;
        steps = `
            To solve this, first find the constant of proportionality k by rearranging and substituting in ${num1} and ${num2} where a = k × b.
            This should get you ${num2} ÷ ${num1} = ${Math.round(num2/num1)}.
            Once you have found k, this should leave you the equation a=${Math.round(num2/num1)} × b.
            Substitute in a=${question_num} into the equation and solve from there to get the answer ${answer}.
        `;
    }

    // Inversely proportional
    if (type === 1) {
        let num1 = getRandomInt(2,15);
        let num2 = getRandomInt(2,15);
        while (num1 === num2) {
            num2 = getRandomInt(2,15);
        }
        let k = num1*num2;
        let question_num = getRandomInt(2,15);
        while (question_num === num2 || (k % question_num !== 0)) {
            question_num = getRandomInt(2,15);
        }

        question = `
            c is inversely proportional to d. When c = ${num1}, d = ${num2}.
            Find the value of c when d = ${question_num}.
        `;
        answer = k/question_num;
        answerLatex = answer;
        steps = `
            To solve this, first find the constant of proportionality k by rearranging and substituting in ${num1} and ${num2} where c = k ÷ d.
            This can be done by multiplying ${num2} × ${num1} = ${Math.round(num2*num1)}.
            Once you have found k, this should leave you the equation c=${Math.round(num2*num1)} ÷ d.
            Substitute in d=${question_num} into the equation and solve from there to get the answer ${Math.round(answer)}.
        `;
    }

    // Directly proportional but with square root
    if (type === 2) {
        let num1 = getRandomInt(2,20);
        let num2 = getRandomInt(2,12);
        while (num1 === num2) {
            num2 = getRandomInt(2,12);
        }
        let question_num = getRandomInt(2,10);
        while (question_num === num1) {
            question_num = getRandomInt(2,10);
        }
        question = `
            g is directly proportional to the square root of h.
            When g = ${num1}, h = ${num2**2}.
            Find the value of h when g = ${question_num}.
        `;

        let k = new Fraction(num1, num2);
        question_num = new Fraction(question_num, 1);
        let answer_fraction = question_num.div(k);
        answer_fraction = answer_fraction.mul(answer_fraction);
        answerLatex = answer_fraction.toLatex();
        answer = answer_fraction;

        steps = `
            To solve this, first find the constant of proportionality k by rearranging and substituting in ${num1} and ${num2**2} where g = k√h.
            Start by finding the square root of ${num2**2} which should get you ${num2}.
            After that you should divide ${num1} by ${num2}. This should get you ${num1} ÷ ${num2} = ${num1}/${num2}.
            This means k=${num1}/${num2}.
            Once you have found k, this should leave you the equation g=(${num1}/${num2})√h.
            Substitute in g=${question_num} into the equation and solve from there to get the answer ${answer}.
        `;
    }

    // Inversely proportional but with square root
    if (type === 3) {
        let num1 = getRandomInt(2,20);
        let num2 = getRandomInt(2,12);
        while (num1 === num2) {
            num2 = getRandomInt(2,12);
        }
        let k = num1*num2;

        let question_num = getRandomInt(2,10);
        while (question_num === num1 || k % question_num !== 0) {
            question_num = getRandomInt(2,10);
        }
        
        question = `
            g is inversely proportional to the square root of h.
            When g = ${num1}, h = ${num2**2}.
            Find the value of h when g = ${question_num**2}.
        `;

        answer = k/question_num;
        answerLatex = answer;
        steps = `
            To solve this, first find the constant of proportionality k by rearranging and substituting in ${num1} and ${num2**2} where g = k ÷ √h.
            Start by finding the square root of ${num2**2} which should get you ${num2}.
            After that you should multiply ${num1} by ${num2}. This should get you ${num1} × ${num2} = ${num1*num2}.
            This means k=${num1*num2}.
            Once you have found k, this should leave you the equation g=(${num1*num2}) ÷ √h.
            Substitute in g=${question_num} into the equation and solve from there to get the answer ${answer}.
        `;
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function FactorisingHarderQuadratics() {
    let question, answer, answerLatex, steps;

    let a1 = getRandomInt(1, 5);
    let a2 = getRandomInt(1, a1);
    
    let p = getRandomInt(-12,12);
    while (p === 0) {
        p = getRandomInt(-12,12);
    }
    let q = getRandomInt(-12,12);
    while (q === 0) {
        q = getRandomInt(-12,12);
    }

    let a = a1*a2;
    let b = (a1*q)+(a2*p);
    let c = p*q;

    question = `Factorise ~${a === 1 ? '' : a}x^{2} ${b > 0 ? `+${b}x` : (b === 0 ? '' : `${b}x`)} ${c > 0 ? `+${c}` : (c === 0 ? '' : `${c}`)} ~`;
    answer = `(${a1 === 1 ? '' : a1}x${p > 0 ? `+${p}` : (p === 0 ? '' : p)})(${a2 === 1 ? '' : a2}x${q > 0 ? `+${q}` : (q === 0 ? '' : q)})`;
    answerLatex = answer;

    steps = `
        To factorise a harder quadratic, start by finding two numbers that multiply to the product of the first and last coefficients and add to the middle coefficient.
        Rewrite the middle term using these numbers, then group the terms into pairs and factorise each pair. 
        Finally, take out the common factor from both groups to write the quadratic in its fully factorised form.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answerLatex}~`],steps];
}

function InverseCompositeFunctions() {
    let question, answer, steps;

    /*
    Function Example Question:
    Given that f(x) = 3x - 5, find f(3)
    or 
    find f(x) = 1 

    Inverse Function Question:
    Given that f(x)=x^2-3, find f^(-1)(x) = 8

    Composite Function Question:
    Given that f(x) = 2x-4 and g(x) = 3x + 5, find gf(3)
    */

    const type = getRandomInt(0,3);

    if (type === 0) {
        let a = getRandomInt(1,10);
        let b = getRandomInt(-10,10);
        while (b === 0) {
            b = getRandomInt(-10,10);
        }
        let expression = `${a}x${b > 0 ? `+${b}` : b}`;
        let num = getRandomInt(-10,10);
        question = `Given that ~f(x) = ${expression}~, find f(${num}).`;
        
        answer = (a*num) + b;
        steps = `
            Substitute the given value of x (in this case, x=${num}) into the function.
            Perform the calculation to find the value of f(${num}), which is ${a}×${num}${b > 0 ? `+${b}` : b}.
            From that you should get the final answer to be ${answer}.
        `;
    }
    if (type === 1) {
        let a = getRandomInt(1,10);
        let b = getRandomInt(-10,10);
        while (b === 0) {
            b = getRandomInt(-10,10);
        }
        let expression = `${a}x${b > 0 ? `+${b}` : b}`;
        answer = getRandomInt(-10,10);
        let num = (a*answer)+b;
        question = `Given that ~f(x) = ${expression}~, find f(x)=${num}.`;
        steps = `
            Start by substituting ${num} instead of f(x).
            From there you can rearrange the equation to find the final answer.
            That should get you the final answer ${answer}.
        `;
    }
    if (type === 2) {
        let a = getRandomInt(1,10);
        let b = getRandomInt(-10,10);
        while (b === 0) {
            b = getRandomInt(-10,10);
        }
        let num = getRandomInt(-10, 10);
        answer = ((num**2)*a);
        if (b < 0) {
            answer = answer + b;
        }
        if (b > 0) {
            answer = answer - b;
        }
        question = `Given that ~f(x)=${a}x^2${b > 0 ? `+${b}` : b}~, find ~f^{-1}(x) = ${num}~`;
        steps = `
            Start by rearranging the inverse function so that x is the subject of the formula.
            Once you have done that substitute ${num} instead of the inverse function and rearrange from there to get an answer.
            This should get you the final answer of ${answer}.
        `;
    }
    if (type === 3) {
        // creating f(x)
        let a1 = getRandomInt(1,10);
        let b1 = getRandomInt(-10,10);
        while (b1 === 0) {
            b1 = getRandomInt(-10,10);
        }
        let expression1 = `${a1}x${b1 > 0 ? `+${b1}` : b1}`;
        let num1 = getRandomInt(-10,10);

        // creating g(x)
        let a2 = getRandomInt(1,10);
        let b2 = getRandomInt(-10,10);
        while (b2 === 0) {
            b2 = getRandomInt(-10,10);
        }
        let expression2 = `${a2}x${b2 > 0 ? `+${b2}` : b2}`;
        let num2 = getRandomInt(-10,10);

        let num_question = getRandomInt(-10,10);
        const subtype = getRandomInt(0,1);
        if (subtype === 0) { // fg(x)
            // find g(x) first
            question = `
                Given that ~f(x) = ${expression1} \\text{ and } g(x)=${expression2}~, find fg(${num_question}).
            `;
            answer = (a1*((a2*num_question)+b2)) + b1;
            steps = `
                Start by substituting ${num_question} into g(x).
                Once you have found an answer from g(x) which should be ${a1*((a2*num_question)+b2)}, you can substitute ${a1*((a2*num_question)+b2)} into f(x).
                After you have substituted that ${a1*((a2*num_question)+b2)} into f(x) you should get the final answer of ${answer}.
            `;
        }
        if (subtype === 1) { // gf(x)
            // find f(x) first
            question = `
                Given that ~f(x) = ${expression1} \\text{ and } g(x)=${expression2}~, find gf(${num_question}).
            `;
            answer = (a2*((a1*num_question)+b1)) + b2;
            steps = `
                Start by substituting ${num_question} into f(x).
                Once you have found an answer from g(x) which should be ${a1*((a2*num_question)+b2)}, you can substitute ${a1*((a2*num_question)+b2)} into g(x).
                After you have substituted that ${a1*((a2*num_question)+b2)} into g(x) you should get the final answer of ${answer}.
            `;
        }
    }

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function CompleteTheSquare() {
    let question, answer, answerLatex, steps;

    let inside_num = getRandomInt(-10,10);
    while (inside_num === 0) {
        inside_num = getRandomInt(-10,10);
    }
    let outside_num = getRandomInt(-10,10);

    let a = 1;
    let b = 2*inside_num;
    let c = (inside_num*inside_num) + outside_num;

    question = `Write ~x^{2}${b > 0 ? `+${b}x` : (b === 0 ? '' : `${b}x`)}${c > 0 ? `+${c}` : (c === 0 ? '' : c)}~ in the form ~(x+a)^{2} + b~ where a and b are integers.`;

    answer = `(x${inside_num > 0 ? `+${inside_num}` : (inside_num === 0 ? '' : `${inside_num}`)})^2${outside_num > 0 ? `+${outside_num}` : (outside_num === 0 ? '' : `${outside_num}`)}`;
    answerLatex = `(x${inside_num > 0 ? `+${inside_num}` : (inside_num === 0 ? '' : `${inside_num}`)})^{2}${outside_num > 0 ? `+${outside_num}` : (outside_num === 0 ? '' : `${outside_num}`)}`;

    steps = `
        Start by halfing the number next to the x which will get you ${inside_num}.
        After that find out what ${inside_num} multiplied by ${inside_num} which should get you ${inside_num*inside_num}.
        Once you have found that, perform ${inside_num*inside_num} + ${c} which should get you the final answer of ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function QuadraticInequalities() {
    let question, answer, steps;

    let num1Inside = getRandomInt(-10,10);
    let num2Inside = getRandomInt(-10,10);

    const a = 1;
    const b = num1Inside + num2Inside;
    let c = num1Inside * num2Inside;
    while (c === 0) {
        num1Inside = getRandomInt(-10,10);
        num2Inside = getRandomInt(-10,10);
        c = num1Inside * num2Inside;
    }

    let signs = ['<', '>'];
    let sign = signs[getRandomInt(0,1)];

    question = `Factorise ~x^{2} ${b > 0 ? `+${b}x` : (b === 0 ? '' : `${b}x`)} ${c > 0 ? `+${c}` : (c === 0 ? '' : c)} ${sign} 0~`;
    /*
    if (c === 0) {
        question = `Factorise ~x^{2} ${b > 0 ? '+'+b+'x' : b+'x'}~`;
    }
    if (b === 0) {
        question = `Factorise ~x^{2} ${c > 0 ? '+'+c : c}~`;
    }*/

    //let expression_brackets = `(x${num1Inside > 0 ? '+'+num1Inside : num1Inside})(x${num2Inside > 0 ? '+'+num2Inside : num2Inside})`;

    let root1 = -num1Inside;
    let root2 = -num2Inside;

    if (sign === '<') {
        if (root1 < root2) {
            answer = `x>${root1}, x<${root2}`;
        }
        if (root1 > root2) {
            answer = `x<${root1}, x>${root2}`;
        }
    }
    if (sign === '>') {
        if (root1 < root2) {
            answer = `x<${root1}, x>${root2}`;
        }
        if (root1 > root2) {
            answer = `x>${root1}, x<${root2}`;
        }
    }

    steps = `
    Start by finding the roots for x^{2}${b > 0 ? `+${b}x` : (b === 0 ? '' : `${b}x`)}${c > 0 ? `+${c}` : (c === 0 ? '' : c)}.
    If the sign is a bigger than 0, this means that the roots will be outside the roots as that is when the y coordinate of any point on the graph will be bigger than 0.
    If the sign is smaller than 0, the x has to be between the roots.
    This means the answer will be ${answer}.
    `;

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}

function ProbabilityEquations() {
    let question, answer, steps;

    let frac = 0;
    //let red_counters = 0;
    let total_counters = getRandomInt(10,30);
    let x = getRandomInt(2,12);
    let ratio1 = getRandomInt(1,10);
    let ratio2 = getRandomInt(2,10);
    while (ratio1 === ratio2) {
        ratio2 = getRandomInt(2,10);
    }

    // checking if the number if prime
    while (isPrime(total_counters)) {
        total_counters = getRandomInt(10,30);
    }

    let red_counters, blue_counters;
    let nom = 0;
    let denom = 0;
    const question_num = 0;
    if (question_num === 0) {
        total_counters = (ratio1+ratio2)*x;

        red_counters = ratio1 * x;
        blue_counters = ratio2 * x;

        let x_split1 = getRandomInt(x,x*2);
        let x_split2 = getRandomInt(1,x_split1);
        let ratios_total = ratio1+ratio2;

        let ratio_multiplied_denom = ratio1 * ((ratio1*x) - 1);
        nom = ratio_multiplied_denom-x_split1;
        
        let ratio_multiplied_nom = ratios_total * ((ratios_total*x) - 1);
        denom = ratio_multiplied_denom-x_split2;

        /*let ratios_total = ratio1+ratio2;
        let split_total_counters1 = getRandomInt(1,total_counters-2);
        let split_total_counters2 = total_counters - split_total_counters1;
        let x_split1 = getRandomInt(x,x*2);
        let x_split2 = getRandomInt(1,x_split1);

        nom = getRandomInt(1,30);
        ratio1 = (ratio1)*((ratio1*x) - 1);
        while (ratio1*nom !== x_split2) {
            nom = getRandomInt(1,30);
        }*/
    }
    const question_templates = [
        `
        There are some red counters and some blue counters in a bag.
        The ratio of red counters to blue counters is ${ratio1}:${ratio2}.
        Two counters are removed at random.
        The probability that both the counters taken are blue is ${nom}/${denom}.
        Work out how many counters were in the bag before any counters were removed.
        `,
        `
        There are ${total_counters} red counters and x blue counters in a bag.
        Two counters are removed from the bag at random.
        The probability that both the counters taken are red is ${frac}.
        Work out the value of x.
        `
    ];
    question = question_templates[question_num];

    if (question_num === 0) {
        total_counters = (ratio1+ratio2)*x;
        answer = total_counters;
    }
    if (question_num === 1) {
        /* Do some stuff */
    }

    steps = '';

    question = question.trim().split(/[\s\t\n]+/).join(' ');
    steps = steps.trim().split(/[\s\t\n]+/).join(' ');

    return [question,[answer,`~${answer}~`],steps];
}