import React, { useEffect } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import WorksheetPreview from '../components/Worksheet Generation/Online/WorksheetPreview';

import BannerHome from '../assets/images/home.png';
import WorkingHome from '../assets/images/working_home.png';
import BuildingHome from '../assets/images/building_home.png';

import BackgroundImage from '../assets/images/cover_blurred.jpg';

import CookiesBanner from '../components/CookiesBanner';

// Feature Card
const FeatureCard = ({ icon, title, description }) => (
<div className="bg-white p-6 rounded-lg border border-gray-100 hover:border-blue-100 hover:shadow-md transition-all">
    <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center mb-4">
    <span className="text-2xl">{icon}</span>
    </div>
    <h3 className="font-bold text-lg mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
</div>
)

// Step Card
const StepCard = ({ number, title, description }) => (
<div className="flex items-start gap-4 p-4 rounded-lg hover:bg-gray-50 transition-colors">
    <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0">
    <span className="font-bold text-blue-600">{number}</span>
    </div>
    <div>
    <h3 className="font-bold text-lg mb-1">{title}</h3>
    <p className="text-gray-600">{description}</p>
    </div>
</div>
)

export default function Home() {

    useEffect(()=> {
        document.title = 'Home | Maths Den';
    }, []);

    return(
        <div className="min-h-screen bg-white">
            <Navbar />

            <CookiesBanner />

            <main className="max-w-4xl mx-auto px-4">
                {/* Hero */}
                <div className="py-20 text-center">
                <h1 className="text-4xl font-bold mb-6">
                    Making Maths Practice 
                    <span className="block text-[#38A1FF] mt-2">Simple</span>
                </h1>
                <p className="text-xl text-gray-600 mb-10 max-w-2xl mx-auto">
                    Create personalized math worksheets in seconds. Perfect for students and teachers who want to make learning more engaging.
                </p>
                <a 
                    href="/gcse" 
                    className="inline-block bg-[#38A1FF] text-white px-8 py-3 rounded-lg hover:bg-blue-500 transition-colors shadow-sm"
                >
                    Start Creating Worksheets →
                </a>
                </div>

                {/* Features */}
                <div className="py-16">
                <h2 className="text-3xl font-bold mb-10 text-center">What we offer</h2>
                <div className="grid md:grid-cols-3 gap-6">
                    <FeatureCard
                    icon="✏️"
                    title="Worksheet Generator"
                    description="Create custom worksheets tailored to your needs in just a few clicks."
                    />
                    <FeatureCard
                    icon="📚"
                    title="Complete Content"
                    description="Access both Foundation and Higher-tier GCSE materials all in one place."
                    />
                    <FeatureCard
                    icon="🎯"
                    title="Perfect Practice"
                    description="Get fresh questions every time for effective learning and revision."
                    />
                </div>
                </div>

                {/* How It Works */}
                <div className="py-16">
                <h2 className="text-3xl font-bold mb-10 text-center">How it works</h2>
                <div className="space-y-6">
                    <StepCard
                    number="1"
                    title="Choose Your Topics"
                    description="Select from our comprehensive list of GCSE Maths topics."
                    />
                    <StepCard
                    number="2"
                    title="Set Your Level"
                    description="Adjust the difficulty to match the tier you are learning (Foundation or Higher)."
                    />
                    <StepCard
                    number="3"
                    title="Get Your Worksheet"
                    description="Generate your personalized worksheet instantly."
                    />
                </div>
                </div>

                {/* CTA */}
                <div className="py-16 text-center bg-gray-50 rounded-2xl my-16">
                <h2 className="text-3xl font-bold mb-6">Ready to practice your maths?</h2>
                
                <div className="space-x-4">
                    <a 
                    href="/gcse" 
                    className="inline-block bg-[#38A1FF] text-white px-8 py-3 rounded-lg hover:bg-blue-500 transition-colors shadow-sm"
                    >
                    Try It Now
                    </a>
                    <a 
                    href="/contact" 
                    className="inline-block bg-white text-[#38A1FF] px-8 py-3 rounded-lg hover:bg-blue-50 transition-colors border border-[#38A1FF]"
                    >
                    Learn More
                    </a>
                </div>
                </div>
            </main>

            <Footer />
        </div>
    );
}