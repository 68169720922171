import React, { useState, useEffect } from "react";

const CookiesBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookieConsent");

        if (!consent) {
            setIsVisible(true);
        }

        // Load Google Consent Mode
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag("consent", "default", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
            ad_user_data: "granted",
            ad_personalization: "granted",
            wait_for_update: 1500,
        });

        gtag("consent", "default", {
            region: [
                "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IS", "IE", "IT",
                "LV", "LI", "LT", "LU", "MT", "NL", "NO", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB", "CH"
            ],
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            wait_for_update: 1500,
        });

        gtag("set", "ads_data_redaction", true);
        gtag("set", "url_passthrough", false);

        // Load Clickio CMP script
        const clickioScript = document.createElement("script");
        clickioScript.src = "//clickiocmp.com/t/consent_242216.js";
        clickioScript.async = true;
        document.body.appendChild(clickioScript);
    }, []);

    // Handle consent
    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "true");
        document.getElementById("cookies_modal").close();
        setIsVisible(false);

        // Update consent mode
        window.gtag("consent", "update", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
            ad_user_data: "granted",
            ad_personalization: "granted",
        });
    };

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "false");
        document.getElementById("cookies_modal").close();
        setIsVisible(false);

        // Update consent mode
        window.gtag("consent", "update", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
        });
    };

    return (
        <div>
            <dialog id="cookies_modal" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Cookies Policy</h3>
                    <p className="py-4">
                        We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.
                    </p>
                    <button onClick={handleAccept} className="btn btn-success text-white rounded-[5px]">Accept</button>
                    <button onClick={handleDecline} className="btn btn-error text-white ml-[5px] rounded-[5px]">Decline</button>

                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn">Close</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default CookiesBanner;