import React from 'react';

export default function Footer() {
    return(
        <div className='mt-20'>
            <div className='mt-[500px]'>
                <footer className="footer footer-center bg-[#ECECEC] text-base-content rounded p-10">
                    <nav className="grid grid-flow-col gap-4">
                        <a className="link link-hover" href='/about'>About us</a>
                        <a className="link link-hover" href='/contact'>Contact</a>
                        <a className="link link-hover" href='/privacy-policy'>Privacy Policy</a>
                    </nav>
                    <aside>
                        <p>Copyright © {new Date().getFullYear()} - All rights reserved by Maths Den</p>
                    </aside>
                </footer>
            </div>
        </div>
    );
}
