import React, { useState, useEffect, useRef } from 'react';

import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

import AuthenticatedNavbar from '../../components/AuthenticatedNavbar';

import { topicDecision } from '../../utils/functions/GCSEQuestionGenerator';

import WorksheetQuestions from '../../components/Worksheet Generation/WorksheetQuestions';
import WorksheetAnswers from '../../components/Worksheet Generation/WorksheetAnswers';

import SaveWorksheet from './SaveWorksheet';

//import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas-pro';
import { useReactToPrint } from 'react-to-print';

import { useNavigate, useLocation } from 'react-router-dom';
//import { useAuthStore } from '../../store/authStore';
import WorksheetPreview from '../../components/Worksheet Generation/Online/WorksheetPreview';
import WorksheetQuestionsButton from '../../components/PDF Creation/WorksheetQuestionsButton';
import WorksheetAnswersButton from '../../components/PDF Creation/WorksheetAnswersButton';

const LatexOptions = {
    delimiters: [
        { left: '~~', right: '~~', display: true },  // Block LaTeX
        { left: '~', right: '~', display: false }, // Inline LaTeX
    ],
  };

const topicData = [
    {
        'topic': 'number', 'colour': '#007FFF', 'subtopic_colour': '#0277ED', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Addition', 'value': 'addition', 'selected': false},
        {'label': 'Subtraction', 'value': 'subtraction', 'selected': false},
        {'label': 'Time', 'value': 'time', 'selected': false},
        {'label': 'Simplifying Fractions', 'value': 'simplify_fractions', 'selected': false},
        {'label': 'Ordering Fractions', 'value': 'ordering_fractions', 'selected': false},
        {'label': 'Place Value', 'value': 'place_value', 'selected': false},
        {'label': 'Rounding', 'value': 'rounding', 'selected': false},
        {'label': 'Negative numbers', 'value': 'negative_numbers', 'selected': false},
        {'label': 'Powers and roots', 'value': 'powers_and_roots', 'selected': false},
        {'label': 'BIDMAS', 'value': 'bidmas', 'selected': false},
        {'label': 'Factors and Multiples', 'value': 'factors_and_multiples', 'selected': false},
        {'label': 'Fractions of an amount', 'value': 'fraction_of_amount', 'selected': false},
        {'label': 'Fractions, Decimals and Percentages', 'value': 'fdp', 'selected': false},
        {'label': 'Averages', 'value': 'averages', 'selected': false},
        {'label': 'Error Intervals', 'value': 'error_intervals', 'selected': false},
        {'label': 'Percentages', 'value': 'percentages', 'selected': false},
        {'label': 'Exchange Rates', 'value': 'exchange_rates', 'selected': false},
        {'label': 'Conversions and Units', 'value': 'conversions_units', 'selected': false},
        {'label': 'Best Buy', 'value': 'best_buy', 'selected': false},
        {'label': 'Compound Interest and Depreciation', 'value': 'compound_interest', 'selected': false},
        {'label': 'Reverse Percentages', 'value': 'reverse_percentages', 'selected': false},
        {'label': 'Standard Form', 'value': 'standard_form', 'selected': false},
        {'label': 'HCF and LCM', 'value': 'hcf_and_lcm', 'selected': false },
        {'label': 'Indices Laws', 'value': 'indices_laws', 'selected': false },
        {'label': 'Estimation', 'value': 'estimation', 'selected': false },
        {'label': 'Fractions', 'value': 'fractions', 'selected': false },
        {'label': 'Percentage Increase', 'value': 'percentage_increase', 'selected': false },
        {'label': 'Percentage Decrease', 'value': 'percentage_decrease', 'selected': false },
        ]
    },
    { 
        'topic': 'algebra', 'colour': '#72D742', 'subtopic_colour': '#69C53D', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Simplifying Algebra', 'value': 'simplify_algebra', 'selected': false},
        {'label': 'Expanding Expressions', 'value': 'expanding_expressions', 'selected': false },
        {'label': 'Factorising Expressions', 'value': 'factorising_expressions', 'selected': false },
        {'label': 'Solving Equations - One Step', 'value': 'solving_equations_one_step', 'selected': false },
        {'label': 'Solving Equations - Two Step', 'value': 'solving_equations_two_step', 'selected': false },
        {'label': 'Solving Equations - Unknown on both sides', 'value': 'solving_equations_both_sides', 'selected': false },
        {'label': 'Solving Equations - Brackets one side', 'value': 'solving_equations_brackets_one_side', 'selected': false },
        {'label': 'Substitution', 'value': 'substitution', 'selected': false },
        {'label': 'nth term', 'value': 'nth_term', 'selected': false },
        {'label': 'Expanding Quadratic expressions', 'value': 'expanding_quadratic_expressions', 'selected': false },
        {'label': 'Factorising Quadratic expressions', 'value': 'factorising_quadratic_expressions', 'selected': false },
        {'label': 'Solving Quadratics', 'value': 'solving_quadratics', 'selected': false },
        {'label': 'Solving Simultaneous Equations', 'value': 'solving_simultaneous_equations', 'selected': false },
        {'label': 'Inequalities', 'value': 'inequalities', 'selected': false},
        ]
    },
    { 
        'topic': 'ratio, proportion & rates of change', 'colour': '#FF4D4D', 'subtopic_colour': '#E33939', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
            {'label': 'Simplifying Ratios', 'value': 'simplifying_ratios', 'selected': false },
            {'label': 'Finding Quantities using ratios', 'value': 'finding_quantities_using_ratios', 'selected': false },
            {'label': 'Ratio', 'value': 'ratio', 'selected': false},
            {'label': 'Proportion', 'value': 'proportion', 'selected': false},
            {'label': 'Direct and Inverse Proportion', 'value': 'direct_inverse_proportion_foundation', 'selected': false },
            {'label': 'Speed', 'value': 'speed', 'selected': false},
        ]
    },
    { 
        'topic': 'probability', 'colour': '#A51CFA', 'subtopic_colour': '#991AE8', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Probability', 'value': 'probability', 'selected': false },
        ]
    },
];

const topicDataHigher = [
    {
        'topic': 'number', 'colour': '#007FFF', 'subtopic_colour': '#0277ED', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Addition', 'value': 'addition', 'selected': false},
        {'label': 'Subtraction', 'value': 'subtraction', 'selected': false},
        {'label': 'Time', 'value': 'time', 'selected': false},
        {'label': 'Simplifying Fractions', 'value': 'simplify_fractions', 'selected': false},
        {'label': 'Ordering Fractions', 'value': 'ordering_fractions', 'selected': false},
        {'label': 'Place Value', 'value': 'place_value', 'selected': false},
        {'label': 'Rounding', 'value': 'rounding', 'selected': false},
        {'label': 'Bounds', 'value': 'bounds', 'selected': false },
        {'label': 'Negative numbers', 'value': 'negative_numbers', 'selected': false},
        {'label': 'Powers and roots', 'value': 'powers_and_roots', 'selected': false},
        {'label': 'BIDMAS', 'value': 'bidmas', 'selected': false},
        {'label': 'Factors and Multiples', 'value': 'factors_and_multiples', 'selected': false},
        {'label': 'Fractions of an amount', 'value': 'fraction_of_amount', 'selected': false},
        {'label': 'Fractions, Decimals and Percentages', 'value': 'fdp', 'selected': false},
        /*{'label': 'Converting recurring decimals to fractions', 'value': 'recurr_to_fraction', 'selected': false},*/
        {'label': 'Averages', 'value': 'averages', 'selected': false},
        {'label': 'Error Intervals', 'value': 'error_intervals', 'selected': false},
        {'label': 'Percentages', 'value': 'percentages', 'selected': false},
        {'label': 'Exchange Rates', 'value': 'exchange_rates', 'selected': false},
        {'label': 'Conversions and Units', 'value': 'conversions_units', 'selected': false},
        {'label': 'Best Buy', 'value': 'best_buy', 'selected': false},
        {'label': 'Compound Interest and Depreciation', 'value': 'compound_interest', 'selected': false},
        {'label': 'Reverse Percentages', 'value': 'reverse_percentages', 'selected': false},
        {'label': 'Standard Form', 'value': 'standard_form', 'selected': false},
        {'label': 'HCF and LCM', 'value': 'hcf_and_lcm', 'selected': false },
        {'label': 'Indices Laws', 'value': 'indices_laws', 'selected': false },
        {'label': 'Fractional and negative indices', 'value': 'frac_neg_indices', 'selected': false },
        {'label': 'Estimation', 'value': 'estimation', 'selected': false },
        {'label': 'Fractions', 'value': 'fractions', 'selected': false },
        {'label': 'Surds', 'value': 'surds', 'selected': false },
        {'label': 'Percentage Increase', 'value': 'percentage_increase', 'selected': false },
        {'label': 'Percentage Decrease', 'value': 'percentage_decrease', 'selected': false },
        {'label': 'Repeated Percentage Change', 'value': 'repeat_percentage_change', 'selected': false },
        {'label': 'The Product Rule for Counting', 'value': 'product_rule_counting', 'selected': false },
        ]
    },
    { 
        'topic': 'algebra', 'colour': '#72D742', 'subtopic_colour': '#69C53D', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Simplifying Algebra', 'value': 'simplify_algebra', 'selected': false},
        {'label': 'Expanding Expressions', 'value': 'expanding_expressions', 'selected': false },
        {'label': 'Factorising Expressions', 'value': 'factorising_expressions', 'selected': false },
        {'label': 'Solving Equations - One Step', 'value': 'solving_equations_one_step', 'selected': false },
        {'label': 'Solving Equations - Two Step', 'value': 'solving_equations_two_step', 'selected': false },
        {'label': 'Solving Equations - Unknown on both sides', 'value': 'solving_equations_both_sides', 'selected': false },
        {'label': 'Solving Equations - Brackets one side', 'value': 'solving_equations_brackets_one_side', 'selected': false },
        {'label': 'Solving Equations using iteration', 'value': 'solving_equations_iteration', 'selected': false },
        {'label': 'Substitution', 'value': 'substitution', 'selected': false },
        {'label': 'nth term', 'value': 'nth_term', 'selected': false },
        /*{'label': 'Finding the nth term of a quadratic sequence', 'value': 'nth_term_quadratic_sequence', 'selected': false },*/
        {'label': 'Expanding Quadratic expressions', 'value': 'expanding_quadratic_expressions', 'selected': false },
        {'label': 'Factorising Quadratic expressions', 'value': 'factorising_quadratic_expressions', 'selected': false },
        {'label': 'Factorising Harder Quadratics', 'value': 'factorising_harder_quadratics', 'selected': false },
        {'label': 'Solving Quadratics', 'value': 'solving_quadratics', 'selected': false },
        {'label': 'Quadratic Formula', 'value': 'quadratic_formula', 'selected': false },
        {'label': 'Expanding triple brackets', 'value': 'expand_triple_brackets', 'selected': false },
        {'label': 'Solving Simultaneous Equations', 'value': 'solving_simultaneous_equations', 'selected': false },
        /*{'label': 'Quadratic Simultaneous Equations', 'value': 'quadratic_simultaneous_equations', 'selected': false },*/
        {'label': 'Inequalities', 'value': 'inequalities', 'selected': false},
        {'label': 'Quadratic Inequalities', 'value': 'quadratic_inequalities', 'selected': false },
        {'label': 'Parallel and Perpendicular lines', 'value': 'par_perp_lines', 'selected': false },
        /*{'label': 'Algebraic Fractions', 'value': 'algebraic_fractions', 'selected': false },*/
        {'label': 'Inverse and composite functions', 'value': 'inverse_composite_functions', 'selected': false },
        {'label': 'Completing the Square', 'value': 'complete_the_square', 'selected': false },
        ]
    },
    { 
        'topic': 'ratio, proportion & rates of change', 'colour': '#FF4D4D', 'subtopic_colour': '#E33939', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
            {'label': 'Simplifying Ratios', 'value': 'simplifying_ratios', 'selected': false },
            {'label': 'Finding Quantities using ratios', 'value': 'finding_quantities_using_ratios', 'selected': false },
            {'label': 'Ratio', 'value': 'ratio', 'selected': false},
            {'label': 'Proportion', 'value': 'proportion', 'selected': false},
            {'label': 'Direct and Inverse Proportion', 'value': 'direct_inverse_proportion_higher', 'selected': false },
            {'label': 'Speed', 'value': 'speed', 'selected': false},
        ]
    },
    { 
        'topic': 'probability', 'colour': '#A51CFA', 'subtopic_colour': '#991AE8', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Probability', 'value': 'probability', 'selected': false },
        /*{'label': 'Probability Equations', 'value': 'probability_equations', 'selected': false },*/
        ]
    },
];

const settings = {
    'question_number': ['Question Number', 5],
    'title': 'Worksheet',
    'tier': 'Foundation',
    'is_online': false,
    'extended': false
};

const questionComplexity = 'basic';
const user = false;

const disabledTopicsOnline = [
'ordering_fractions',
'error_intervals',
'solving_quadratics',
'quadratic_formula',
'frac_neg_indices',
'par_perp_lines',
'quadratic_inequalities'
];

export default function CreateWorksheet() {

    const [topics, setTopics] = useState(topicData);
    const [filteredTopics, setFilteredTopics] = useState("");
    const [viewWorksheet, setViewWorksheet] = useState(false);
    const [worksheetQuestions, setWorksheetQuestions] = useState([]);
    const [worksheetSettings, setWorksheetSettings] = useState(settings);
    const [questionNumber, setQuestionNumber] = useState(5);
    const [worksheetOnline, setWorksheetOnline] = useState(false);
    const [currentTier, setCurrentTier] = useState('foundation');

    const [showWorksheet, setShowWorksheet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userSelectedTopics, setUserSelectedTopics] = useState([]);
    
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [printing, setPrinting] = useState(false);

    const [printMode, setPrintMode] = useState('Questions');

    const [saveWorksheetScreen, setSaveWorksheetScreen] = useState(false);

    const [onlineDisabled, setOnlineDisabled] = useState(false);
    const [onlineEnabledLabel, setOnlineEnabledLabel] = useState('No');

    //const { isAuthenticated, user, logout, fetchUser } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();

    /*useEffect(() => {
        const checkAuth = async () => {
            await fetchUser();
        };

        checkAuth();
    }, [fetchUser]);*/

    useEffect(()=> {
        for (let i=0; i < disabledTopicsOnline.length; i++) {
            if (userSelectedTopics.includes(disabledTopicsOnline[i])) {
                let currentWorksheetSettings = worksheetSettings;
                currentWorksheetSettings.is_online = false;
                setWorksheetOnlineSettings(false);
                setOnlineDisabled(true);
                break;
            }
            if (i === disabledTopicsOnline.length-1) {
                setOnlineDisabled(false);
            }
        }
    }, [userSelectedTopics]);

    const changeDifficulty = (tier) => {
        setCurrentTier(tier);
        clearTopics();

        if (tier === 'foundation') {
            setTopics(topicData);
        }
        if (tier === 'higher') {
            setTopics(topicDataHigher);
        }
    }

    const displayedTopics = topics.map((topic) => {
        const filteredSubtopics = filteredTopics
            ? topic.subtopics.filter((subtopic) =>
                subtopic.label.toLowerCase().includes(filteredTopics.toLowerCase())
            )
            : topic.subtopics; // Show all subtopics if no search query

        return {
            ...topic,
            filteredSubtopics: filteredSubtopics, // All subtopics if no search or filtered subtopics
            extended: filteredTopics && filteredSubtopics.length > 0 ? true : topic.extended // Set extended to true if there's a search query
        };
    });

    const toggleTopic = (topicName) => {
        const updatedTopics = topics.map((topic) => {
            if (topic.topic === topicName) {
                return { ...topic, extended: !topic.extended };
            }
            return topic;
        });
        setTopics(updatedTopics);
    }

    const toggleSubtopic = (subtopicId) => {
        const updatedTopics = topics.map((topic) => {
            const updatedSubtopics = topic.subtopics.map((subtopic) => {
            if (subtopic.value === subtopicId) {
                return { ...subtopic, selected: !subtopic.selected }; // Toggle selected state
            }
            return subtopic; // Return the subtopic as is if it does not match
            });
            return { ...topic, subtopics: updatedSubtopics }; // Update topic with modified subtopics
        });
        setTopics(updatedTopics); // Update the state with the modified topics

        // Update selected values for userSelectedTopics
        const selectedValues = updatedTopics.flatMap(topic =>
            topic.subtopics.filter(subtopic => subtopic.selected).map(subtopic => subtopic.value)
        );
        setUserSelectedTopics(selectedValues); // Update state for selected subtopics
    };

    const toggleWorksheetSettings = () => {
        
        if (worksheetSettings.extended === false) {
            setWorksheetSettings({
                ...worksheetSettings, extended: true
            });
            return;
        }
        if (worksheetSettings.extended === true) {
            setWorksheetSettings({
                ...worksheetSettings, extended: false
            });
            return;
        }
    }

    const setNumberQuestions = (number) => {
        setQuestionNumber(number);
        let currentWorksheetSettings = worksheetSettings;
        currentWorksheetSettings.question_number[1] = number;

        setWorksheetSettings(currentWorksheetSettings);
    }

    const setWorksheetOnlineSettings = (online) => {
        let online_new = Boolean(online);

        // Create a copy of the current worksheet settings
        const updatedWorksheetSettings = { ...worksheetSettings };

        // Update the `is_online` property with the new checkbox value
        updatedWorksheetSettings.is_online = online_new;

        if (online_new === true) {
            setOnlineEnabledLabel('Yes');
        } else {
            setOnlineEnabledLabel('No');
        }

        // Update the state with the modified settings object
        setWorksheetSettings(updatedWorksheetSettings);
    }

    const setWorksheetTitle = (title) => {
        setWorksheetSettings((prevSettings) => ({
            ...prevSettings,
            title: title,
          }));
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const generateWorksheet = async() => {
        // clearing the current worksheet
        setWorksheetQuestions([]);

        // getting the selected subtopics
        var selectedTopics = [];

        for (let i=0; i < topics.length; i++) {

            for (let x=0; x < topics[i].subtopics.length; x++) {
                if (topics[i].subtopics[x].selected === true) {
                    selectedTopics.push(topics[i].subtopics[x].value);
                }
            }
        }

        if (selectedTopics.length === 0) {
            //alert('Error - no topics selected!');
            setErrorMessage('No topics have been selected!');
            document.getElementById('my_modal_1').showModal();
            return;
        }
        if (worksheetSettings.title === '') {
            setErrorMessage('Please set a worksheet title!');
            document.getElementById('my_modal_1').showModal();
            return;
        }
        if (selectedTopics.length !== 0 && worksheetSettings.title !== '') {
            setLoading(true);
        }

        let questions = [];

        if (selectedTopics.length > 0 && worksheetSettings.title !== '') {
            const promises = [];

            let topicNumber = 0;
            let numberOfQuestions = worksheetSettings.question_number[1];
            console.log(worksheetSettings);
            while (numberOfQuestions > 0) {
                questions.push(topicDecision(selectedTopics[topicNumber], currentTier));
                topicNumber += 1;
                if (topicNumber > selectedTopics.length-1) {
                    topicNumber = 0;
                }
                if (numberOfQuestions === 1) {
                    await delay(500);
                }
                numberOfQuestions -= 1;
            }

            setWorksheetQuestions(questions);
            setViewWorksheet(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            if (selectedTopics.length === 0) {
                //alert('Error - no topics selected!');
                document.getElementById('my_modal_1').showModal();
            }
            if (worksheetSettings.title === '') {
                alert('Error - please set a worksheet title!');
            }
        }
    }

    const clearTopics = () => {
        const resetTopics = topics.map(topic => {
            const resetSubtopics = topic.subtopics.map(subtopic => ({
                ...subtopic,
                selected: false // Set selected to false
            }));
            return { ...topic, subtopics: resetSubtopics }; // Update topic with reset subtopics
        });
    
        setTopics(resetTopics); // Update state with reset topics
        setUserSelectedTopics([]); // Clear userSelectedTopics
    }

    const printWorksheet = async (worksheet, worksheetType) => {
        const input = document.getElementById(worksheet); // Get the element by ID
        const sections = input.getElementsByClassName('page'); // Get all elements with the "page" class

        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = 210; // Width of A4 in mm
        const pdfHeight = 297; // Height of A4 in mm

        const margin = { top: 10, bottom: 10, left: 15, right: 15 };
        const contentWidth = pdfWidth - margin.left - margin.right;
        const contentHeight = pdfHeight - margin.top - margin.bottom;

        setPrinting(true);

        // Temporarily show hidden elements
        input.classList.remove('hidden-to-print');

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];

            const canvas = await html2canvas(section, {
                scale: 3,
                useCORS: true,
            });

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = contentWidth;
            const imgHeight = (canvas.height * contentWidth) / canvas.width;

            const xOffset = margin.left;
            const yOffset = margin.top;

            pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);

            if (i < sections.length - 1) {
                pdf.addPage();
            }
        }

        input.classList.add('hidden-to-print'); // Re-hide elements after generating the PDF

        let worksheetFilename = worksheetSettings.title.replaceAll(' ','_');
        if (worksheetType === 'Answers') {
            worksheetFilename += '_answers';
        }

        pdf.save(worksheetFilename+'.pdf');
        setPrinting(false);
    }

    // Only render when user and isAuthenticated are valid
    /*if ((!isAuthenticated || !user) && (location.pathname === '/create_worksheet')) {
        navigate('/');
    }*/

    return (
        <div>
            {location.pathname === '/create_worksheet' && user && <AuthenticatedNavbar user={user} />}

            <dialog id="my_modal_1" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-red-500">Error</h3>
                    <p className="py-4 font-bold text-red-500">🚨 {errorMessage} 🚨</p>
                    <p>Click the ✕ button to close</p>
                    <div className="modal-action">
                    <form method="modal-box">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        </form>
                    </form>
                    </div>
                </div>
            </dialog>

            { showWorksheet === false &&
            <div className='mt-[20px] pb-[100px]'>
                {location.pathname === '/create_worksheet' &&
                <div>
                    <h1 className='text-3xl text-center'><b>GCSE Worksheet</b></h1>
                    <p className='text-center mb-[10px]'>Create a GCSE Maths Worksheet</p>
                </div> }
                {
                    loading === true &&
                    <div className='flex flex-col items-center ml-auto mr-auto'>
                        <span className="loading loading-spinner loading-lg mt-[10px] mb-[10px]"></span>
                        <p className='text-center'>Loading...</p>
                    </div>
                }
                <div className='mt-[20px]'>
                    {
                        viewWorksheet ?
                            <div>
                                { loading === false &&
                                <div>
                                    { worksheetOnline &&
                                        <div>
                                            <input onClick={()=>setWorksheetOnline(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '125px'}} value='Back to edit' /> 
                                            <WorksheetPreview worksheet={worksheetQuestions} />
                                        </div>
                                    }
                                    { worksheetSettings.is_online === false ?
                                        <div className='w-[300px] sm:w-[350px] md:w-[500px] lg:w-[700px] ml-auto mr-auto'>
                                            { saveWorksheetScreen ?
                                                <div>
                                                    { !worksheetOnline &&
                                                    <div>
                                                        <h2 className='text-center text-xl mb-[10px]'><b>Save Worksheet</b></h2>
                                                        {/*<p className='text-center text-[red]'><b>If you have edited a question, please remember to edit the answer and steps as well!</b></p>
                                                        <p className='text-center'>Choose an option below to decide what to do with your newly generated worksheet.</p>*/}
                                                        
                                                        { 
                                                            onlineDisabled ?
                                                            <div className="tooltip tooltip-error ml-auto mr-auto flex items-center" data-tip="Online Worksheet completion is disabled due to topics selected.">
                                                                <input type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mt-[10px] disabled:text-[white] disabled:cursor-not-allowed' style={{backgroundColor: '#6e6d6d', width: '225px'}} value='Complete worksheet online' /> 
                                                            </div>
                                                            :
                                                            <input onClick={()=>setWorksheetOnline(true)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mt-[10px] disabled:text-[white] disabled:cursor-not-allowed' style={{backgroundColor: '#624CAB', width: '225px'}} value='Complete worksheet online' /> 
                                                        }
                                                        {/*<input onClick={()=>printWorksheet('questions', 'Questions')} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mt-[10px]' style={{backgroundColor: '#DD7373', width: '225px'}} value='Save to PDF' /> */}
                                                        <button className='btn btn-neutral ml-auto mr-auto flex justify-center mt-[10px] mb-[30px] rounded-[5px] w-[225px] text-white' onClick={()=>document.getElementById('my_modal_2').showModal()}>Download</button>
                                                        <dialog id="my_modal_2" className="modal">
                                                        <div className="modal-box">
                                                            <h3 className="text-center text-lg">Choose the document you would like to download</h3>
                                                            
                                                            <select value={printMode} onChange={(e)=>setPrintMode(e.target.value)} className="select select-bordered w-[200px] flex justify-center ml-auto mr-auto mt-[10px] rounded-[10px]">
                                                                <option selected disabled>Pick an option</option>
                                                                <option>Questions</option>
                                                                <option>Answers</option>
                                                            </select>

                                                            {
                                                                printMode === 'Questions' &&
                                                                <WorksheetQuestionsButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'questions'} />
                                                            }
                                                            {
                                                                printMode === 'Answers' &&
                                                                <WorksheetAnswersButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'answers'} />
                                                            }

                                                            <div className="modal-action">
                                                            <form method="dialog">
                                                                {/* if there is a button in form, it will close the modal */}
                                                                <button className="btn rounded-[10px]">Close</button>
                                                            </form>
                                                            </div>
                                                        </div>
                                                        </dialog>
                                                        
                                                        

                                                        {/*<WorksheetQuestionsButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'questions'} />
                                                        <WorksheetAnswersButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'answers'} />*/}
                                                        {/*<input onClick={()=>printWorksheet('answers', 'Answers')} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px] mt-[10px]' style={{backgroundColor: '#DD7373', width: '225px'}} value='Save Answers to PDF' /> */}
                                                        { user &&
                                                        <div>
                                                            {user.teacher === true && <input onClick={()=>alert('saving worksheet to profile')} type='submit' className='btn bg-[#38A1FF] hover:bg-blue-500 transition-colors hover:cursor-pointer flex justify-center mb-[10px] mt-[10px]' style={{width: '200px'}} value='Save Worksheet to Profile' />}
                                                        </div>
                                                        }
                                                        <input onClick={()=>setSaveWorksheetScreen(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '225px'}} value='Back to Worksheet' /> 

                                                        <div className={printing ? 'mt-[800px]' : ''}>
                                                            <div id='questions' className='hidden-to-print'>
                                                                <WorksheetQuestions worksheetQuestions={worksheetQuestions} worksheetTitle={worksheetSettings.title} />
                                                            </div>
                                                            <div id='answers' className='hidden-to-print'>
                                                                <WorksheetAnswers worksheetQuestions={worksheetQuestions} worksheetTitle={worksheetSettings.title} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    <div>
                                                        <input onClick={()=>setViewWorksheet(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '150px'}} value='Back to Menu' /> 
                                                        <input onClick={()=>setSaveWorksheetScreen(true)} type='submit' className='btn ml-auto mr-auto rounded-md text-white bg-[#38A1FF] hover:bg-blue-500 hover:cursor-pointer flex justify-center mb-[10px]' style={{width: '150px'}} value='Save worksheet' /> 
                                                    </div>

                                                    <div className='border-solid border-2 border-[black] pt-[10px] pb-[10px] pl-[25px] pr-[25px] rounded-[10px]'>
                                                        <h2 className='text-center'><b><u>{worksheetSettings.title}</u></b></h2>
                                                        <div>
                                                            {
                                                                worksheetQuestions.map((question, questionNumber)=> (
                                                                    <div key={questionNumber*1000} className='mb-[20px] mt-[30px]'>
                                                                        <div>
                                                                            <div>
                                                                                <p 
                                                                                className='mb-[10px] break-words' 
                                                                                data-testid='question_text'>Q{questionNumber+1}. <Latex delimiters={LatexOptions.delimiters}>{question[0]}</Latex></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='h-[300px] border-solid border-2 border-[black] rounded-[5px]'>
                                                                            <div className='flex items-end justify-end mt-[260px] mr-[10px] break-words overflow-hidden'>
                                                                                <p>...............................................................</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="divider before:bg-[black] after:bg-[black]">
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='border-solid border-2 border-[black] pt-[10px] pb-[10px] pl-[25px] pr-[25px] mt-[20px] rounded-[10px]'>
                                                        <h2><b><u>{worksheetSettings.title} answers</u></b></h2>
                                                        {
                                                            worksheetQuestions.map((question, questionNumber)=> (
                                                                <div key={questionNumber*1000} className='mb-[10px]'>
                                                                    <div className='break-words'>
                                                                        Q{questionNumber+1}. <Latex delimiters={LatexOptions.delimiters}>{question[0]}</Latex>
                                                                        <br/>
                                                                        <div>
                                                                            <div>
                                                                                <div className='break-words'>
                                                                                    Answer: <Latex delimiters={LatexOptions.delimiters}>{question[1][1]}</Latex>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div>
                                                                            <div>
                                                                                Steps: {question[2]}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <input onClick={()=>setViewWorksheet(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '150px'}} value='Back to menu' /> 
                                            <input onClick={()=>setWorksheetOnlineSettings(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#624CAB', width: '150px'}} value='Edit Worksheet' /> 
                                            <WorksheetPreview worksheet={worksheetQuestions} />
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                        :
                        <div>
                            { loading === false &&
                                <div className='lg:w-[800px] md:w-[80%] sm:w-[70%] w-[80%] ml-auto mr-auto '>
                                    {/* Search Bar with SVG Icon */}
                                    <div className="relative mb-[10px]">
                                        <input
                                        type="text"
                                        placeholder="Search subtopics..."
                                        value={filteredTopics}
                                        onChange={(e) => setFilteredTopics(e.target.value)} // Update the search query state
                                        className="w-full p-2 border rounded pl-8 pr-8" // Set full width, padding for the icon
                                        style={{ height: '40px' }} // Fixed height for consistent sizing
                                        />
                                        <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-search"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='mb-[10px]'>
                                        {displayedTopics.map((topic, i) => (
                                            <div className='mb-[10px] border rounded-lg overflow-hidden' key={i*1000}>
                                                <button onClick={() => toggleTopic(topic.topic)} className='w-full p-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors flex justify-between items-center'>
                                                    {topic.topic.charAt(0).toUpperCase() + topic.topic.slice(1)}
                                                </button>
                                                {(topic.extended || (filteredTopics && topic.filteredSubtopics.length > 0)) && (
                                                    <div>
                                                    {topic.filteredSubtopics.map((subtopic, x) => (
                                                        <div key={x}>
                                                        <div
                                                            className="p-2"
                                                        >
                                                            <div className="flex justify-between items-center">
                                                            <p className='ml-2 text-gray-700'>{subtopic.label}</p>
                                                            <input
                                                                type="checkbox"
                                                                onChange={() => toggleSubtopic(subtopic.value)} // Toggle subtopic on change using unique id
                                                                checked={subtopic.selected} // Checked state
                                                                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                                                style={{ backgroundColor: topic.subtopic_colour }}
                                                            />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                        }

                                        <div className='mb-[10px] border rounded-lg overflow-hidden'>
                                            <button type='submit' onClick={()=>toggleWorksheetSettings()} className='w-full p-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors flex justify-between items-center'>
                                                Worksheet Settings
                                            </button>
                                            {
                                                worksheetSettings.extended &&
                                                <div>
                                                    {
                                                        Object.entries(worksheetSettings).map(([setting, value], i)=>
                                                            <div key={i*1000}>
                                                                { setting !== 'extended' &&
                                                                <div>
                                                                    {
                                                                        setting === 'question_number' &&
                                                                        <div>
                                                                            <p className='pl-2 pt-2'>Question Number:</p>
                                                                            <div className='p-2'>
                                                                                <select value={questionNumber} onChange={(event)=>setNumberQuestions(event.target.value)} className="select select-bordered w-full rounded-md border border-gray-300 p-2">
                                                                                    <option>5</option>
                                                                                    <option>10</option>
                                                                                    <option>15</option>
                                                                                    <option>20</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        setting === 'title' &&
                                                                        <div>
                                                                            <p className='pl-2 pt-2'>Worksheet Title:</p>
                                                                            <div className='p-2'>
                                                                                <input
                                                                                    type="text"
                                                                                    className="input input-bordered w-full rounded-md"
                                                                                    placeholder="Title"
                                                                                    onChange={(e)=>setWorksheetTitle(e.target.value)}
                                                                                    value={worksheetSettings.title}
                                                                                    maxLength={40}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        setting === 'tier' &&
                                                                        <div>
                                                                            <p className='pl-2 pt-2'>Tier:</p>
                                                                            <div className='p-2'>
                                                                                <select value={currentTier} onChange={(e)=>changeDifficulty(e.target.value)} className="select select-bordered w-full rounded-md border border-gray-300 p-2">
                                                                                    <option value={'foundation'}>Foundation</option>
                                                                                    <option value={'higher'}>Higher</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        setting === 'is_online' && (!user || user.teacher === true) &&
                                                                        <div>
                                                                            <p className='pl-2 pt-2'>Do you want to complete online now?</p>
                                                                            <div className='p-2'>
                                                                                {/*<input type='checkbox' disabled={onlineDisabled} onChange={(e)=>setWorksheetOnlineSettings(e.target.checked)} className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500" />*/}
                                                                                <select 
                                                                                    disabled={onlineDisabled} 
                                                                                    onChange={(e) => setWorksheetOnlineSettings(e.target.value === 'true')} 
                                                                                    className="select select-bordered w-full rounded-md border border-gray-300 p-2"
                                                                                    >
                                                                                    <option value="false">No</option>
                                                                                    <option value="true">Yes</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                }
                                                            </div>
                                                    )}
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            <div className='mb-[10px] border rounded-lg overflow-hidden'>
                                                <button type='submit' onClick={()=>clearTopics()} className='w-full p-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors flex justify-between items-center'>
                                                    Clear Topics
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            { loading === false &&
                            <div className='w-[300px] ml-auto mr-auto flex flex-col justify-center'>
                                <input onClick={()=>generateWorksheet()} type='submit' className='btn hover:cursor-pointer mt-[25px] bg-[#38A1FF] hover:bg-blue-500 transition-colors text-white rounded-md'  value='Create Worksheet' /> 
                                <div>
                                    {
                                        worksheetQuestions.length > 0 ?
                                        <input onClick={()=>setViewWorksheet(true)} type='submit' className='btn topicButton hover:cursor-pointer mt-[10px]' style={{backgroundColor: '#9C9C9C'}} value='Back to Worksheet' /> 
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div>
            </div>
            }
        </div>
    );
}
