import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CookiesBanner from "../components/CookiesBanner";

export const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Privacy Policy | Maths Den";
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <CookiesBanner />
            <div className="mt-5 flex-grow">
                <h1 className="text-3xl text-center font-bold">
                    Privacy Policy
                </h1>
                <div className="max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto mt-5 px-4">
                    <div className="text-left">
                        <p>
                            At Maths Den, accessible from https://www.mathsden.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Maths Den and how we use it.
                        </p>
                        <h2 className="text-2xl font-bold mt-4">Information We Collect</h2>
                        <p>
                            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your information.
                        </p>
                        <p>
                            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message, and/or attachments you may send us.
                        </p>
                        <h2 className="text-2xl font-bold mt-4">How We Use Your Information</h2>
                        <p>We use the information we collect in various ways, including to:</p>
                        <ul className="list-disc list-inside mt-2">
                            <li>Provide, operate, and maintain our website</li>
                            <li>Improve, personalize, and expand our website</li>
                            <li>Understand and analyze how you use our website</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>Communicate with you, either directly or through one of our partners</li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>
                        <h2 className="text-2xl font-bold mt-4">Log Files</h2>
                        <p>
                            Maths Den follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics.
                        </p>
                        <h2 className="text-2xl font-bold mt-4">Cookies and Web Beacons</h2>
                        <p>
                            Like any other website, Maths Den uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited.
                        </p>
                        <h2 className="text-2xl font-bold mt-4">Your Data Protection Rights</h2>
                        <p>
                            You have rights under data protection law, including the right to access, correct, or delete your personal data.
                        </p>
                        <h2 className="text-2xl font-bold mt-4">Contact Us</h2>
                        <p>
                            If you have any questions about this Privacy Policy, you can contact us at:
                        </p>
                        <p>Email: <a className="text-[#38A1FF] underline" href="mailto:contact@mathsden.com">contact@mathsden.com</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;